export default new RegExp(
  [
    " ",
    "!",
    '"',
    "#",
    "\\$",
    "%",
    "&",
    "'",
    "\\(",
    "\\)",
    "\\*",
    "\\+",
    ",",
    "-",
    "\\.",
    "/",
    ":",
    ";",
    "<",
    "=",
    ">",
    "\\?",
    "@",
    "\\[",
    "\\\\",
    "\\]",
    "\\^",
    "_",
    "`",
    "{",
    "\\|",
    "}",
    "~",
  ].join("|")
);
