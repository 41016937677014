/* global MutationObserver */
import { useEffect, useState } from "react";

// Options for the observer (which mutations to observe)
const config = { childList: true };

const targetIsV1Cmp = (el) => el.dataset && el.dataset.qa === "oil-Layer";
const cmpModalPresent = () => Array.from(document.body.childNodes).some(targetIsV1Cmp);

const callback = (setCmpLoaded) => (mutationsList) => {
  mutationsList.some((mutation) => {
    const addedEl = mutation.addedNodes[0];
    if (addedEl) {
      if (targetIsV1Cmp(addedEl)) {
        setCmpLoaded(true);
        return true;
      }
    }
    const removedEl = mutation.removedNodes[0];
    if (removedEl) {
      if (targetIsV1Cmp(removedEl)) {
        setCmpLoaded(false);
        return true;
      }
    }
    return false;
  });
};

const cmpEventListener = (setCmpLoaded) => (event) => {
  const eventType = event?.detail?.eventType;
  if (!eventType) return;
  if (eventType === "cmpui:visible") return setCmpLoaded(true);
  if (eventType === "cmpui:closed") return setCmpLoaded(false);
};

export default (target) => {
  const [cmpLoaded, setCmpLoaded] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const observer = new MutationObserver(callback(setCmpLoaded));
      observer.observe(target, config);
      const listener = cmpEventListener(setCmpLoaded);
      document.addEventListener("gravito:tcfv2:client", listener);
      setCmpLoaded(cmpModalPresent());
      return () => {
        observer.disconnect();
        document.removeEventListener("gravito:tcfv2:client", listener);
      };
    }
    return () => {};
  }, []);

  return cmpLoaded;
};
