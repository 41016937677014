import fetch from "unfetch";

const mergeArrays = ([...rest]) => rest.reduce((acc, arr) => ({ ...acc, ...arr }), {});
export default async (domain, clientID, lng) => {
  if (domain === null && clientID === null) return null;

  //replace test with dev just in case. for test-tenant
  domain = domain.replace("test", "dev");

  const confsUrl = `https://confs.${domain}/`;
  const postfix = lng && lng !== "null" && lng !== "fi" ? `_${lng}` : "";
  const getAsset = async (c) => fetch(`${confsUrl}${c}${postfix}.json`).then((r) => r.json());
  return Promise.all(["common", "default", clientID].map(getAsset))
    .then(mergeArrays)
    .catch((error) => error);
};
