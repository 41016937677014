import React from "react";

import { useUIContext } from "../providers/UIContextProvider";
import LoginNotification from "./LoginNotification";
import PwdlessExpired from "./PwdlessExpiredNotification";
import SignupNotification from "./SignupNotification";

const getNotification = (user, error) => {
  if (user?.name)
    return user.role === "nonverified" ? <SignupNotification user={user} /> : <LoginNotification user={user} />;
  if (error?.pwdless) return <PwdlessExpired />;
  return <></>;
};

export default () => {
  const { core, shouldRenderNotification, error } = useUIContext();
  if (!shouldRenderNotification) return <></>;
  const user = core.getUser();

  return getNotification(user, error);
};
