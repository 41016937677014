import "../polyfills";

import CoreInit from "./AlmaTunnusCore";
import ReactInit from "./AlmaTunnusReact";

const extInterface = {
  render: ReactInit,
  init: CoreInit.init,
};

export default extInterface;
