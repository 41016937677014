import providers from "../ga/providers";
import sessionStore from "../storageAPIs/sessionStore";
import isPasswordlessFlow from "./isPasswordlessFlow";

const getLoginEvent = (provider) => {
  const base = "login";

  if (provider === "silent") return `${base}-${provider}`;

  const someProvider = providers[provider];
  if (isPasswordlessFlow()) return `${base}-passwordless`;
  else return someProvider ? `${base}-${someProvider}` : base;
};

export default (config, state, funcs, provider) => (result, cb) => {
  const loginProvider = provider || sessionStore.getSomeLoginFlow();
  const eventParameters = [result, getLoginEvent(loginProvider), config.storedSource];
  const sendAuthenticationEvent = funcs.analytics.sendAuthenticationEvent;
  return state.analyticsWait
    ? () => {
        sendAuthenticationEvent(...eventParameters, cb);
      }
    : () => {
        sendAuthenticationEvent(...eventParameters);
        cb();
      };
};
