import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const A = (props) => <Text id={props.id || null}>{props.children}</Text>;

A.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

export default A;

// STYLED COMPONENT
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

const Text = styled.span`
  font-size: 12px;
  color: #ff0000;
  text-align: center;
  display: block;
  max-width: 300px;
  margin-top: 5px;
`;
