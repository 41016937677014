import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { LinkButtonFluid, PrimaryButton } from "../components/Buttons";
import { H1 } from "../components/Headers";
import IconError from "../components/icons/Error";
import P from "../components/P";
import { useModalStageContext } from "../providers/modal/ModalStageContext";
import { useUIContext } from "../providers/UIContextProvider";
import NotificationFrame from "./NotificationFrame";

const PwdlessExpiredNotification = ({ closeNotification }) => {
  const { t, error } = useUIContext();
  const { setModalStage } = useModalStageContext();
  const onPwdlessClick = () => {
    closeNotification();
    setModalStage("PWDLESS");
  };

  const isBlockedError = error && error.errorDescription === "user is blocked";
  return (
    <>
      <IconError />
      {isBlockedError ? (
        <>
          <H1custom>{t("errors.blocked_user")}</H1custom>
        </>
      ) : (
        <>
          <H1custom>{t("notifications.passwordlessExpired.heading")}</H1custom>
          <P>
            {t("notifications.passwordlessExpired.description")}
            <LinkButtonFluid onClick={onPwdlessClick}>
              {t("notifications.passwordlessExpired.labelRequestNew")}
            </LinkButtonFluid>
          </P>
        </>
      )}
      <PrimaryButtonCustom type="button" onClick={closeNotification}>
        {t("common.labelClose")}
      </PrimaryButtonCustom>
    </>
  );
};

PwdlessExpiredNotification.propTypes = {
  closeNotification: PropTypes.func.isRequired,
};

const H1custom = styled(H1)`
  margin: 20px auto 15px auto;
  max-width: 310px;
`;

const PrimaryButtonCustom = styled(PrimaryButton)`
  margin-top: 20px;
`;

export default NotificationFrame(PwdlessExpiredNotification);
