import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { LinkButton, PrimaryButton, StyledButton } from "../components/Buttons";
import P from "../components/P";
import { useUIContext } from "../providers/UIContextProvider";
import { generateText } from "../utils/text-tag-utils";
import NotificationFrame from "./NotificationFrame";

const LoginNotification = ({ user, closeNotification }) => {
  const { core, t, logoutPopup } = useUIContext();

  const handleLogout = core.getLogoutHandler(logoutPopup?.url, logoutPopup?.callback, logoutPopup?.windowFeatures);

  const notification = generateText(t("notifications.login.description"), user).join("");

  return (
    <LoginNotificationContainer role="alert">
      <P dangerouslySetInnerHTML={{ __html: notification }} />
      <PrimaryButton type="button" id="alma-tunnus-notification-button-close" onClick={closeNotification}>
        {t("common.labelClose")}
      </PrimaryButton>
      <LinkButton type="button" id="alma-tunnus-notification-button-logout" onClick={handleLogout}>
        {t("common.labelLogout")}
      </LinkButton>
    </LoginNotificationContainer>
  );
};

const LoginNotificationContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin: 20px 0 -10px 0;

  ${StyledButton} {
    margin: 20px 0 20px 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.mobileM}) {
    margin: 30px 0 -20px 0;
  }
`;

LoginNotification.propTypes = {
  user: PropTypes.object.isRequired,
  closeNotification: PropTypes.func.isRequired,
};

export default NotificationFrame(LoginNotification);
