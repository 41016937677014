import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import AlertMessage from "../../components/AlertMessage";
import { LinkButton, PrimaryButton } from "../../components/Buttons";
import { H1 } from "../../components/Headers";
import Input from "../../components/input/Input";
import P from "../../components/P";
import { useModalStageContext } from "../../providers/modal/ModalStageContext";
import { useUIContext } from "../../providers/UIContextProvider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;
  > #email {
    margin-top: 20px;
  }
  > p {
    text-align: center;
    margin: 0;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.mobileL}) {
    min-height: 430px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
`;

const EmailSendingForm = ({ sendAction, nextModal, assetScope }) => {
  const { setModalStage, modalData } = useModalStageContext();
  const [state, setState] = useState({ error: null, performingSend: false });
  const emailInput = useRef("");
  const { t, core } = useUIContext();
  const getErrorMessage = (e) => {
    return {
      error: e.error || e.code || e.errorCode,
      text: e.code || e.errorCode ? t(`errors.${e.code || e.errorCode}`) : e.message,
    };
  };

  const handleClick = async () => {
    const error = core.validationErrorGetters.email(emailInput.current.value);
    if (error) return setState({ ...state, error: error ? getErrorMessage(error) : null });
    setState({ ...state, performingSend: true });
    const result = await sendAction(emailInput.current.value);

    return result.status === 200 || result.status === 500
      ? setModalStage(nextModal)
      : setState({
          ...state,
          error: getErrorMessage(await result.json()),
          performingSend: false,
        });
  };

  const handleKeyDown = (e) => e.key === "Enter" && handleClick();
  const handleBack = () => {
    const email = emailInput.current.value;
    return setModalStage("LOGIN", core.getEmailValidity(email) ? { username: email } : {});
  };
  useEffect(() => emailInput.current.focus(), []);

  return (
    <Container role="dialog" aria-labelledby="heading" aria-describedby="description">
      <H1 id="heading" dangerouslySetInnerHTML={{ __html: t(`pages.${assetScope}.heading`) }} />
      <P id="description" paragraphContent={t(`pages.${assetScope}.description`)} />
      <InputContainer>
        <Input
          defaultValue={modalData.username || modalData.email || ""}
          type="email"
          tabIndex="0"
          id="email"
          label={t("common.labelEmail")}
          ref={emailInput}
          onKeyDown={handleKeyDown}
        />
        {state.error && (
          <AlertMessage>
            <P role="alert" dangerouslySetInnerHTML={{ __html: state.error.text }} />
          </AlertMessage>
        )}
        <PrimaryButton onClick={handleClick} performingAction={state.performingSend}>
          {t("common.labelSend")}
        </PrimaryButton>
      </InputContainer>
      <LinkButton onClick={handleBack}>{t("common.labelBack")}</LinkButton>
    </Container>
  );
};

EmailSendingForm.propTypes = {
  sendAction: PropTypes.func.isRequired,
  nextModal: PropTypes.string.isRequired,
  assetScope: PropTypes.string.isRequired,
};

export default EmailSendingForm;
