import PropTypes from "prop-types";
import React, { useReducer } from "react";

import ModalStageContext from "./ModalStageContext";

const reducer = (state, { type, payload }) => (type === "setState" ? payload : state);

const ModalStageProvider = ({ children, ...rest }) => {
  const [state, dispatch] = useReducer(reducer, { modalStage: -1, modalData: {} });
  const setModalStage = (modalStage, data) =>
    dispatch({ type: "setState", payload: { modalStage, modalData: { ...data } } });

  const closeModalOnClick = () => setModalStage(-1);

  return (
    <ModalStageContext.Provider
      value={{
        isUniversal: false,
        modalData: state.modalData,
        modalStage: state.modalStage,
        setModalStage,
        closeModalOnClick,
        ...rest,
      }}
    >
      {children}
    </ModalStageContext.Provider>
  );
};

ModalStageProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default ModalStageProvider;
