import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import Spinner from "../components/animated/Spinner";
import { LinkButton, LinkButtonFluid, PrimaryButton, StyledButton } from "../components/Buttons";
import { Container } from "../components/Containers";
import { H1 } from "../components/Headers";
import P from "../components/P";
import { useUIContext } from "../providers/UIContextProvider";
import { generateText } from "../utils/text-tag-utils";
import NotificationFrame from "./NotificationFrame";

const H1custom = styled(H1)`
  margin: 25px 0 15px 0;
  color: ${(props) => props.theme.buttonColor};
`;

const ControlsContainer = styled.div`
  max-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -10px;

  ${StyledButton} {
    margin: 20px 0 20px 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.mobileM}) {
    margin-bottom: -20px;
  }
`;

const SpinnerContainer = styled.div`
  margin: 25px 0 40px 0;
`;

const RegisterComplete = ({ setState, closeNotification, user }) => {
  const [loading, setLoading] = useState(false);
  const { t, core, logoutPopup } = useUIContext();

  const handleLogout = core.getLogoutHandler(logoutPopup?.url, logoutPopup?.callback, logoutPopup?.windowFeatures);

  const handleSendVerificationClick = async () => {
    setLoading(true);
    const r = await core.sendVerificationEmail(user);
    setLoading(false);
    if (r.ok) setState({ view: 1 });
    else setState({ view: 2, error: r.error || r.errors });
  };

  return (
    <Container>
      <H1custom tabIndex={-1} id="notification_label">
        {t("notifications.signup.heading")}
      </H1custom>
      <Container id="notification_desc">
        <P dangerouslySetInnerHTML={{ __html: generateText(t("notifications.signup.description"), user).join("") }} />
        <P dangerouslySetInnerHTML={{ __html: t("notifications.signup.verificationRequest") }} />
      </Container>

      <ControlsContainer>
        {loading ? (
          <SpinnerContainer>
            <Spinner size="35px" border="5px" />
          </SpinnerContainer>
        ) : (
          <>
            <P>
              {t("notifications.signup.missingVerificationEmail")}
              <LinkButtonFluid
                role="link"
                aria-label={t("notifications.signup.resentVerificationEmail")}
                onClick={handleSendVerificationClick}
              >
                {t("notifications.signup.resentVerificationEmail")}
              </LinkButtonFluid>
            </P>

            <PrimaryButton
              type="button"
              tabIndex={0}
              id="alma-tunnus-button-register-complete"
              onClick={closeNotification}
            >
              {t("common.labelClose")}
            </PrimaryButton>
            <LinkButton type="button" onClick={handleLogout}>
              {t("common.labelLogout")}
            </LinkButton>
          </>
        )}
      </ControlsContainer>
    </Container>
  );
};

RegisterComplete.propTypes = {
  setState: PropTypes.func.isRequired,
  closeNotification: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default NotificationFrame(RegisterComplete);
