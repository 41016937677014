import React from "react";
import styled from "styled-components";

const WindowsLive = () => (
  <Icon
    clip-rule="evenodd"
    fill-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="nonzero">
      <path d="m0 0h10.421v10.421h-10.421z" fill="#f25022" />
      <path d="m0 11.579h10.421v10.421h-10.421z" fill="#00a4ef" />
      <path d="m11.579 0h10.421v10.421h-10.421z" fill="#7fba00" />
      <path d="m11.579 11.579h10.421v10.421h-10.421z" fill="#ffb900" />
    </g>
  </Icon>
);

export default WindowsLive;

const Icon = styled.svg``;
