import trimFormData from "../functions/trimFormData";
import { validateLoginForm, validateRegisterForm } from "../functions/Validators";
import localStore from "../storageAPIs/localStore";
import sessionStore from "../storageAPIs/sessionStore";
import TunnusSharedFunctions from "../TunnusSharedFunctions";
import clearUrl from "./clearUrl";
import InitCheck from "./InitCheck";
import LoginFunctions from "./LoginFunctions";
import { getLoginHandler, getLogoutHandler } from "./PopupHandlers";
import SessionFunctions from "./SessionFunctions";
import RegisterHandler from "../RegisterHandler";
import LoginHandler from "../LoginHandler";
import storeToken from "./storeToken";

const EmbeddedCoreUserInterface = (config, state, funcs, userStore, lng) => {
  const loginFunctions = LoginFunctions(funcs.authenticator, userStore);
  const sessionFunctions = SessionFunctions(config, state, funcs, loginFunctions);

  const removeSession = () => localStore.removeIdTokenData();

  const clearUser = () => userStore.clearUser();

  const redirectUniversal = (options = {}) => {
    sessionStore.setOriginUrl(window.location.href);
    sessionStore.setSource(options.source || state.source);
    sessionStore.setType(options.type || state.type);
    sessionStore.setLoginState(options.loginState || state.loginState);
    sessionStore.setLang(state.lang || config?.lng);
    funcs.authenticator.redirectToUniversal({ ...options, state, lng, lang: sessionStore.getLang() });
  };

  const isFacebookApp = (ua = navigator.userAgent || navigator.vendor || window.opera) =>
    ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;

  const isHuaweiBrowser = (ua = navigator.userAgent || navigator.vendor || window.opera) =>
    ua.indexOf("HuaweiBrowser") > -1;

  const isInstagram = (ua = navigator.userAgent || navigator.vendor || window.opera) => ua.indexOf("Instagram") > -1;

  const isFirefox = () => {
    if (typeof document === "undefined") return false;
    return "MozAppearance" in document.documentElement.style;
  };
  const reset = () => {
    clearUser();
    removeSession();
  };

  const logout = (rTo) => {
    removeSession();
    const returnTo = rTo || config.returnTo;
    if (state.analyticsWait && funcs.analytics.isLoaded()) {
      return funcs.analytics.sendLogout(() => loginFunctions.logout(returnTo));
    }
    funcs.analytics.sendLogout();
    return loginFunctions.logout(returnTo);
  };

  const processRegisterFormData = async (formData) => {
    try {
      const trimmedFromData = trimFormData(formData);
      const userData = await validateRegisterForm(trimmedFromData);
      return userData;
    } catch (validationErrors) {
      funcs.analytics.sendRegistration(false);
      const isConsentMissingError = validationErrors.find((e) => e.error === "consent");
      if (isConsentMissingError) {
        funcs.analytics.sendRegistration(false, true);
      }
      return validationErrors;
    }
  };

  const getOriginUrl = () => sessionStore.getOriginUrl();

  const callRegisterLoginCallback = (loginResult) => {
    if (loginResult.errors) {
      return config.loginCallback(loginResult.errors, null);
    }
    const originUrl = getOriginUrl();
    config.loginCallback(null, { ...loginResult, originUrl });
  };

  const initRegisterHandler = (source) => {
    const registerHandler = RegisterHandler(funcs.authenticator, config, funcs.analytics);
    registerHandler.setSource(source);
    sessionStore.setSource(source);
    return registerHandler;
  };

  const initLoginHandler = (source) => {
    const scope = "gaevent:registration";
    const loginHandler = LoginHandler(funcs.analytics, scope);
    loginHandler.setLoginFunction(funcs.authenticator.login);
    loginHandler.withSession(storeToken, loginFunctions.setUserSession);
    loginHandler.setSource(source);
    sessionStore.setSource(source);
    return loginHandler;
  };

  const formatRegisterLoginResult = (loginResult) => {
    if (loginResult.errors) {
      return { errors: loginResult.errors };
    } else {
      return loginResult;
    }
  };

  return Object.assign({}, TunnusSharedFunctions(config, state, funcs), {
    runInitCheck: () => {
      if (config.runInitCheck !== false)
        InitCheck(config, state, funcs, loginFunctions)
          .run()
          .then((callback) => callback());
    },
    clearUrl,
    storageIsAvailable: state.storageIsAvailable,
    canPopup: () => !(isFacebookApp() || isHuaweiBrowser() || isInstagram()),
    isRedirectToHostedPage: () =>
      !state.cookiesEnabled || isFacebookApp() || isInstagram() || isHuaweiBrowser() || isFirefox(),
    getCookiesEnabled: () => state.cookiesEnabled,
    getEvents: () => funcs.events,
    reset,
    getCMPData: () => state.CMPData,
    getB2BCMPData: () => state.B2BCMPData,
    login: async (username, password, suppressCallback = false) => {
      try {
        await sessionFunctions.validate3rdPCEnabled();
        await validateLoginForm(username, password);
        const result = await loginFunctions.login(username, password);
        if (!suppressCallback) config.loginCallback(null, result);
        funcs.analytics.sendAuthenticationEvent(result.user, "login");
        return { success: result, errors: null };
      } catch (error) {
        funcs.analytics.sendLoginFailure("login");
        if (!suppressCallback) config.loginCallback(error, null);
        return { success: null, errors: Array.isArray(error) ? error : [error] };
      }
    },
    logout,
    registerAndLoginUser: async (formData, options) => {
      const userData = await processRegisterFormData(formData);
      const userDataIsValid = !Array.isArray(userData);
      if (userDataIsValid) {
        const source = options?.source;
        const type = options?.type || state.type;

        const registerHandler = initRegisterHandler(source);

        const registerResult = await registerHandler.register(userData, type);
        if (registerResult?.errors) {
          const regResult = formatRegisterLoginResult(registerResult);
          return regResult;
        }

        const loginHandler = initLoginHandler(source);
        const loginResult = await loginHandler.login(userData);
        callRegisterLoginCallback(loginResult);

        const result = formatRegisterLoginResult(loginResult);
        return result;
      } else {
        const validationResult = { errors: userData };
        return validationResult;
      }
    },

    redirectToHostedLogin: (options = {}) => redirectUniversal(options),

    redirectToHostedRegistration: (options = {}) => redirectUniversal({ ...options, modal: "REGISTER" }),

    setSessionFromSilentLogin: async () => {
      try {
        const result = await loginFunctions.silentNoPromptLogin(true, true);
        funcs.analytics.sendAuthenticationEvent(result.user, "login-silent");
        const originUrl = getOriginUrl();
        config.loginCallback(null, { ...result, originUrl });
        return result;
      } catch (error) {
        funcs.analytics.sendLoginFailure("login-silent");
        return funcs.logger.log(error);
      }
    },

    checkSession: (allowSilent, simpleReturn) => {
      return sessionFunctions.checkSession(allowSilent, simpleReturn);
    },

    checkExternalSession: (isRefresh = false, simpleReturn = true) => {
      return state.cookiesEnabled ? sessionFunctions.checkExternalSession(isRefresh, simpleReturn) : false;
    },

    getAccessToken: () => sessionFunctions.getAccessToken(),

    getIdToken: localStore.getIdTokenData,

    idTokenIsValid: async () => {
      const idTokenData = localStore.getIdTokenData();
      return idTokenData
        ? funcs.authenticator
            .verifyIdToken(idTokenData)
            .then(() => true)
            .catch(() => false)
        : null;
    },

    browserIsSupported: () =>
      typeof window !== "undefined" &&
      window.safari === undefined &&
      !isFirefox() &&
      !isHuaweiBrowser() &&
      !isFacebookApp() &&
      !isInstagram(),

    getUser: userStore.getUser,

    getUpdatedUser: async () => {
      const idTokenData = localStore.getIdTokenData();
      return idTokenData
        ? funcs.authenticator
            .verifyIdToken(idTokenData)
            .then((idToken) => {
              userStore.setUser(idToken, false);
              return userStore.getUser();
            })
            .catch(() => false)
        : false;
    },

    getIdJWT: async (options) => {
      const refresh = options?.refresh;
      const idTokenData = localStore.getIdTokenData();
      if (!idTokenData) return null;
      if (refresh && state.cookiesEnabled) {
        const refreshResult = await funcs.authenticator.silentLogin();
        await loginFunctions.handleSilentResult(true, false)(refreshResult);
        return {
          idToken: refreshResult.idToken,
          idTokenPayload: refreshResult.idTokenPayload,
        };
      } else {
        const idTokenPayload = await funcs.authenticator.verifyIdToken(idTokenData);
        return { idToken: idTokenData.idToken, idTokenPayload };
      }
    },

    cookiesEnabled: () => state.cookiesEnabled,

    getLogoutHandler: getLogoutHandler(reset, funcs, logout),

    getLoginHandler: getLoginHandler(config, loginFunctions, funcs, state),
  });
};

export default EmbeddedCoreUserInterface;
