import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Bullet = ({ fillColor }) => (
  <Icon
    fillColor={fillColor}
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.41421"
    viewBox="0 0 302 302"
  >
    <circle cx="151" cy="151" r="75" />
  </Icon>
);

Bullet.defaultProps = {
  fillColor: null,
};
Bullet.propTypes = {
  fillColor: PropTypes.string,
};

export default Bullet;

const Icon = styled.svg`
  fill: ${({ theme, fillColor }) => fillColor || theme.linkColor};
`;
