import React from "react";
import styled from "styled-components";

export default () => (
  <Icon
    clip-rule="evenodd"
    fill-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="1.41421"
    viewBox="0 0 41 40"
    width="40"
    aria-labelledby="alma-media-logo__footer"
  >
    <title id="alma-media-logo__footer">Alma Media logo</title>
    <path d="m0 4.733.003-4.733 19.268.037.064 18.814-19.335-.003.028-11.798 14.489.027-.035-2.384-14.482.04zm14.51 9.407.002-2.412-9.671.03.015 2.374z" />
    <path d="m21.667 25.883v-4.734l19.269.038.064 18.813-19.335-.002.03-11.799 14.485.028-.036-2.384zm14.505 9.406.006-2.411-9.671.032.016 2.372z" />
    <g fillRule="nonzero">
      <path d="m26.528.007.033 14.13 14.439-.001-.01 4.709-19.317.006-.008-18.851z" />
      <path d="m19.335 21.149-.032 18.822-4.773.029-.079-14.19h-2.312l-.061 14.19h-4.842l.024-14.19h-2.356l-.032 14.19h-4.853l-.019-18.839z" />
    </g>
  </Icon>
);

const Icon = styled.svg`
  fill: ${(props) => props.theme.darkGray};
`;
