import localStore from "../storageAPIs/localStore";
import checkSSOSession from "./checkSSOSession";
import checkUserToken from "./checkUserToken";
import formatUserData from "./user/formatUserData";

const SessionFunctions = (config, state, funcs, loginFunctions) => ({
  validate3rdPCEnabled: async () => {
    if (state.cookiesEnabled) return true;
    throw new Error("third_party_cookies_disabled");
  },

  checkExternalSession: async (isRefresh, simpleReturn) => {
    const { result, callback } = await checkSSOSession(
      config,
      state,
      funcs,
      loginFunctions,
      funcs.logger
    )(isRefresh || state.tokenExpired, simpleReturn);
    callback();
    return result;
  },

  checkSession: async (allowSilent = true, simpleReturn = false) => {
    return checkUserToken(
      state,
      funcs
    )(localStore.getIdTokenData()).then(async (userData) => {
      if (userData !== false && userData.email_verified) {
        const idToken = localStore.getIdTokenData().idToken;
        const user = formatUserData(userData);
        config.checkSessionCallback(user, idToken);
        return { user, idToken };
      }
      if (!(state.cookiesEnabled && allowSilent)) {
        config.checkSessionCallback(false);
        return false;
      } else {
        const hasToken = !!localStore.getIdTokenData();
        const { result, callback } = await checkSSOSession(
          config,
          state,
          funcs,
          loginFunctions,
          funcs.logger
        )(hasToken, simpleReturn);
        callback();
        return result;
      }
    });
  },

  getAccessToken: async () =>
    funcs.authenticator
      .checkSession()
      .then((result) => result.accessToken)
      .catch((error) => funcs.logger.error("AccessToken fetch failed: " + JSON.stringify(error), "error")),
});

export default SessionFunctions;
