import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Letter = ({ fillColor }) => (
  <Icon clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="1.41421" viewBox="0 0 282 210">
    <g fill={fillColor}>
      <path
        d="m281.25 190.153c0 10.547-8.55 19.097-19.097 19.097-51.312 0-191.744 0-243.056 0-10.547 0-19.097-8.55-19.097-19.097 0-39.806 0-131.25 0-171.056 0-10.547 8.55-19.097 19.097-19.097h243.056c10.547 0 19.097 8.55 19.097 19.097zm-127.745-51.852c-7.294 6.663-18.466 6.663-25.76 0-25.25-23.065-80.041-73.113-94.298-86.135-.602-.55-1.473-.693-2.219-.364s-1.228 1.068-1.228 1.884v117.57c0 2.12.842 4.154 2.341 5.653s3.532 2.341 5.652 2.341h205.264c2.12 0 4.153-.842 5.652-2.341s2.341-3.533 2.341-5.653c0-24.534 0-98.265 0-117.57 0-.816-.482-1.555-1.228-1.884s-1.617-.186-2.219.364c-14.257 13.022-69.048 63.07-94.298 86.135zm70.165-104.723c.627-.573.838-1.472.531-2.264-.308-.792-1.07-1.314-1.92-1.314-25.756 0-137.556 0-163.312 0-.85 0-1.612.522-1.92 1.314-.307.792-.096 1.691.531 2.264 14.142 12.918 62.542 57.128 78.15 71.385 2.772 2.532 7.018 2.532 9.79 0 15.608-14.257 64.008-58.467 78.15-71.385z"
        fillRule="nonzero"
      />
    </g>
  </Icon>
);

Letter.defaultProps = {
  fillColor: "#000",
};
Letter.propTypes = {
  fillColor: PropTypes.string,
};

export default Letter;

const Icon = styled.svg``;
