export const getConsentObject = (facebook, standard, google, ga, linkedin, apple) => ({
  facebook,
  standard,
  google,
  ga,
  linkedin,
  apple,
});
export const getObjectConsent = (consent) =>
  getConsentObject(consent.facebook, consent.standard, consent.google, consent.ga, consent.linkedin, consent.apple);
export const getSingleValueConsent = (consent) =>
  getConsentObject(consent, consent, consent, consent, consent, consent);
export const setCMPData = (state, CMPData) => {
  state.CMPData = CMPData;
};

export const setB2BCMPData = (state, B2BCMPData) => {
  state.B2BCMPData = B2BCMPData;
};
export const setConsentsFromConfig = (state, config) => {
  const consent = config.consent;
  if (typeof consent === "boolean") setCMPData(state, getSingleValueConsent(consent));
  else if (typeof consent === "object") setCMPData(state, getObjectConsent(consent));
};

export const allowWaitGAConfig = (consent) =>
  typeof consent === "boolean" ? consent !== false : !consent || consent.ga === true;
export const setAnalyticsWait = (state, config) => {
  state.analyticsWait = config.analyticsWait && (allowWaitGAConfig(config) || state.CMPData.ga);
};
