const getStorage = () => localStorage.getItem("ALMA");
const almaDataExists = (almaData = getStorage()) => almaData !== null && typeof almaData !== "undefined";
const storageExists = () => {
  try {
    return localStorage !== null && typeof localStorage !== "undefined";
  } catch (_) {
    return false;
  }
};

const insertEntry = (key, value, almaData) => (almaDataExists() ? { ...almaData, [key]: value } : { [key]: value });
const setItem = (key, value) =>
  storageExists() && localStorage.setItem("ALMA", JSON.stringify(insertEntry(key, value, JSON.parse(getStorage()))));

const getEntry = (key, almaData) => (almaDataExists() ? JSON.parse(almaData)[key] : undefined);
const getItem = (key) => (storageExists() ? getEntry(key, getStorage()) : null);

const removeEntry = (key, aData) => {
  const almaData = aData;
  if (almaDataExists(almaData)) {
    delete almaData[key];
    return almaData;
  }
  return {};
};
const removeItem = (key) => {
  if (storageExists()) {
    const newAlmaData = removeEntry(key, JSON.parse(getStorage()));
    if (newAlmaData !== null && newAlmaData !== undefined && Object.keys(newAlmaData).length !== 0) {
      localStorage.setItem("ALMA", JSON.stringify(newAlmaData));
    } else {
      localStorage.removeItem("ALMA");
    }
  }
};

export default {
  setIdTokenData: (value) => setItem("id_token_data", value),
  getIdTokenData: () => getItem("id_token_data"),
  removeIdTokenData: () => removeItem("id_token_data"),
  setUsermeta: (value) => setItem("usermeta", value),
  getUsermeta: () => getItem("usermeta"),
  clear: () => localStorage.removeItem("ALMA"),
};
