import React from "react";
import styled from "styled-components";

import A from "../components/A";
import AlmaLogo from "../components/icons/AlmaLogo";
import { TextRegular, TextSmall } from "../components/Text";
import { useUIContext } from "../providers/UIContextProvider";

const Footer = () => {
  const { t } = useUIContext();

  return (
    <FooterContainer>
      <AlmaLogo />
      <span>
        <A href={t("common.termsLink")}>{t("common.labelTerms")}</A>
        <Divider>&emsp;|&emsp;</Divider>
        <A href={t("common.privacyLink")}>{t("common.labelPrivacy")}</A>
      </span>
    </FooterContainer>
  );
};

export default React.memo(Footer);

const FooterContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  & svg {
    max-height: 41px;
    width: 40px;
  }
  & > span {
    ${TextSmall}
    & > a {
      padding: 3px 0;
      display: block;
      font-size: 13px;
    }

    text-align: center;
    box-sizing: content-box;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.mobileS}) {
    & > span {
      text-wrap: wrap;
      & > a {
        padding: 0;
        display: inline-block;
        font-size: 14px;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.mobileM}) {
    padding: 25px 25px;
    & > span {
      ${TextRegular}
      & > a {
        font-size: inherit;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.mobileL}) {
    padding: 25px 30px;
  }
`;

const Divider = styled.span`
  @media (max-width: 319px) {
    display: none;
  }
`;
