export default (config) => {
  const isProd = config.domain === "tunnus.almamedia.fi" || config.domain === "almamedia.eu.auth0.com";

  return {
    componentVersion: process.env.COMPONENT_VERSION,
    buildTime: process.env.BUILD_TIME,
    selfServiceUrl: isProd
      ? "https://itsepalvelu-api.tunnus.almamedia.io"
      : "https://itsepalvelu-api.tunnus-dev.almamedia.net",
    apiAudience: isProd ? "https://almamedia.eu.auth0.com/api/v2/" : "https://almamedia-dev.eu.auth0.com/api/v2/",
    gaAlma: isProd ? "UA-53865955-1" : "UA-53865955-2",
    gaNonInteraction: isProd,
    disableGa: config?.disableGa,
  };
};
