import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Checkmark = ({ fillColor }) => (
  <Icon height="10" viewBox="0 0 12 10" width="12">
    <path
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="m4.93463 6.54313 5.01056-6.4452662c.08581-.1104985.24531-.1304052.35571-.0444525l1.6012 1.2455987c.1105.08595.1304.24546.0445.35596l-6.41136 8.24715c-.02783.03585-.06341.06217-.10253.07836l-.00144.00059-.00101.00042c-.08103.03271-.1769.02261-.25077-.03483l-5.0816442-3.95332c-.1104595-.08587-.1303591-.24537-.044521-.35587l1.2452452-1.60173c.08592-.11049.24528-.13048.35574-.04453z"
      fill={fillColor || "#93b91f"}
      fillRule="evenodd"
    />
  </Icon>
);

Checkmark.defaultProps = {
  fillColor: null,
};
Checkmark.propTypes = {
  fillColor: PropTypes.string,
};

export default Checkmark;

const Icon = styled.svg``;
