export const loginFailure = "sendLoginFailure";

export const authenticationEvent = "sendAuthenticationEvent";

export const someFailure = "sendSomeFailure";

export const logout = "sendLogout";

export const registration = "sendRegistration";

export const passwordReset = "sendPasswdReset";
