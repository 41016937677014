import Tracker, { CustomTracker } from "./Tracker";

export default (config, state, analyticsState, logger) => {
  const setSiteAnalytics = (sAnalytics) => {
    if (typeof ga !== "function") return;
    sAnalytics.forEach((trackerConf) =>
      trackerConf.name && trackerConf.account
        ? analyticsState.trackers.push(CustomTracker({ ...trackerConf, nonInteraction: state.env.gaNonInteraction }))
        : logger.error("Tracker parameter missing. name, account required")
    );
    analyticsState.siteAnalyticsSet = true;
    analyticsState.analytics = [];
    analyticsState.eventQueue = analyticsState.eventQueue.filter((f) => f() && false);
  };

  const loadAnalytics = () => {
    if (typeof ga !== "function") {
      (function (i, s, o, g, r, a, m) {
        i["GoogleAnalyticsObject"] = r;
        (i[r] =
          i[r] ||
          function () {
            (i[r].q = i[r].q || []).push(arguments);
          }),
          (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");
    }
    analyticsState.trackers.push(Tracker("almaTunnus", state.env.gaAlma, state.env.gaNonInteraction));
    if (config.analytics) setSiteAnalytics(config.analytics, state.env.gaNonInteraction);
  };

  if (
    typeof window !== "undefined" &&
    typeof document !== "undefined" &&
    (!config?.disableGa || config?.extraParams?.disable_ga === "false")
  ) {
    try {
      loadAnalytics(state.env, config);
    } catch (e) {
      logger.error(e);
    }
  }

  const setDisabled = (disabled) => analyticsState.trackers.forEach((tracker) => tracker.setDisabled(disabled));

  return {
    setDisabled,
    setSiteAnalytics,
    loadAnalytics,
    initSiteAnalytics: (sAnalytics) => !analyticsState.siteAnalyticsSet && sAnalytics && setSiteAnalytics(sAnalytics),
  };
};
