import { css } from "styled-components";

import { generateFontFamily } from "../utils/font-manager";

const text = css`
  font-family: ${generateFontFamily("GalanoGrotesqueAlt-Regular")};
  letter-spacing: -0.3px;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const TextLarge = css`
  ${text}
  font-size: ${(props) => props.theme.fontLarger};
`;

export const TextRegular = css`
  ${text}
  font-size: ${(props) => props.theme.fontLarge};
`;

export const TextSmall = css`
  ${text}
  font-size: ${(props) => props.theme.fontNormal};
`;

export const TextTiny = css`
  ${text}
  font-size: ${(props) => props.theme.fontTiny};
`;

export const TextBold = (textType) => css`
  ${textType}
  font-family: ${generateFontFamily("GalanoGrotesqueAlt-Bold")};
`;

export const TextSemiBold = (textType) => css`
  ${textType}
  font-family: ${generateFontFamily("GalanoGrotesqueAlt-SemiBold")};
`;

export const TextSemiBoldInheritSize = css`
  font-family: ${generateFontFamily("GalanoGrotesqueAlt-SemiBold")};
`;

export const TextPrimaryButton = css`
  ${text}
  font-family: ${generateFontFamily("GalanoGrotesqueAlt-SemiBold")};
  letter-spacing: normal;
  font-size: ${(props) => props.theme.fontLarger};
`;

export const TextSomeButton = css`
  ${text}
  font-family: ${generateFontFamily("GalanoGrotesqueAlt-SemiBold")};
  font-size: ${(props) => props.theme.fontLarge};
`;

export const GoogleButton = css`
  ${text}
  font-family: ${generateFontFamily("Roboto")};
  font-size: ${(props) => props.theme.fontLarge};
  color: #FFF;
  `;
