import tags from "./text-tags";

// Calls fn1 for every other array element and fn2 for the rest.
const mapAlternate = (arr, fn1, fn2) => arr.map((x, i) => ((i + 1) % 2 === 0 ? fn2(x, i) : fn1(x, i)));

// Replaces a matching %TAG% with the appropriate html template.
const getElementFromTag = (tag, i, value, funcs) => {
  const [tagCode, tagText] = tag.split(",");
  const foundTag = tags[tagCode];
  if (foundTag) return foundTag(tagText || value, i, funcs);
  return null;
};

const tagRegex = /%|%/g; // Tag: % %

export const generateText = (text, value, funcs) =>
  text &&
  mapAlternate(
    text.split(tagRegex),
    (x) => x,
    (x, i) => getElementFromTag(x, i, value, funcs)
  );
