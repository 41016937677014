const errors = {
  emailInvalid: {
    error: "email",
    code: "email_invalid",
  },
  passwordStrength: {
    error: "password",
    code: "password_strength_error",
  },
  passwordLength: {
    error: "password",
    code: "length_at_least",
  },
  passwordMatch: {
    error: "password",
    code: "password_no_match",
  },
  loginPasswordInvalid: {
    error: "password",
    code: "login_password_invalid",
  },
  registerPasswordInvalid: {
    error: "password",
    code: "password_invalid",
  },
  consentRequired: {
    error: "consent",
    code: "consent_required",
  },
  invalidRequest: {
    error: "invalidRequest",
    code: "invalid_request",
  },
  invalidFirstName: { error: "firstName", code: "invalid_firstname" },
  invalidLastName: { error: "lastName", code: "invalid_lastname" },
  missingBothNames: { error: "bothName", code: "missing_names" },
  missingFirstName: { error: "firstName", code: "missing_firstname" },
  missingLastName: { error: "lastName", code: "missing_lastname" },
};

export default errors;
