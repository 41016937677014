import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import BulletS from "../../components/icons/Bullet";
import OkS from "../../components/icons/Checkmark";

const ErrorS = () => (
  <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="1.41421" viewBox="0 0 20 20">
    <line x1="4" y1="4" x2="16" y2="16" stroke="red" strokeWidth="3" />
    <line x1="4" y1="16" x2="16" y2="4" stroke="red" strokeWidth="3" />
  </svg>
);

const ChecklistSymbol = styled.div`
  svg {
    display: block;
    height: 12px;
    width: 12px;
    margin-right: 4px;
  }
`;

const Symbol = ({ ok, focus }) => {
  const symbolToUse = (() => {
    if (typeof ok === "undefined") return <BulletS fillColor="#000000" />;
    if (ok) return <OkS />;
    return focus ? <BulletS fillColor="#000000" /> : <ErrorS />;
  })();
  return <ChecklistSymbol>{symbolToUse}</ChecklistSymbol>;
};

Symbol.defaultProps = {
  ok: undefined,
  focus: false,
};

Symbol.propTypes = {
  ok: PropTypes.bool,
  focus: PropTypes.bool,
};

export default Symbol;
