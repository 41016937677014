import React from "react";
import styled from "styled-components";

const Facebook = () => (
  <Icon
    clip-rule="evenodd"
    fill-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="1.41421"
    viewBox="0 0 1024 1024"
  >
    <path
      d="m1024 512c0-282.77-229.23-512-512-512s-512 229.23-512 512c0 255.554 187.231 467.37 432 505.78v-357.78h-130v-148h130v-112.8c0-128.32 76.438-199.2 193.39-199.2 56.017 0 114.61 10 114.61 10v126h-64.562c-63.603 0-83.438 39.467-83.438 79.957v96.043h142l-22.7 148h-119.3v357.78c244.769-38.41 432-250.226 432-505.78z"
      fill="#fff"
      fillRule="nonzero"
    />
  </Icon>
);

export default Facebook;

const Icon = styled.svg``;
