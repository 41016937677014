import React from "react";
import styled from "styled-components";

const Error = () => (
  <Icon clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="1.41421" viewBox="0 0 302 302">
    <g fill="#f00">
      <path d="m168.672 210.693c0-9.903-8.04-17.943-17.943-17.943h-.022c-9.903 0-17.944 8.04-17.944 17.943s8.041 17.944 17.944 17.944h.022c9.903 0 17.943-8.041 17.943-17.944z" />
      <path d="m165.752 78.007c0-1.66-1.347-3.007-3.007-3.007h-24.055c-1.66 0-3.007 1.347-3.007 3.007v86.236c0 1.659 1.347 3.007 3.007 3.007h24.055c1.66 0 3.007-1.348 3.007-3.007z" />
      <path
        d="m151.205 0c46.689.222 92.29 23.159 120.272 60.564 27.957 37.373 37.29 88.11 23.585 133.622-12.783 42.449-45.374 78.621-86.548 95.752-45.041 18.74-99.079 13.866-140.15-13.002-41.6-27.213-68.364-75.537-68.364-126.219 0-49.241 25.622-97.712 67.228-125.468 24.427-16.296 53.548-25.109 83.002-25.249h.975zm-.886 28c-44.447.212-87.536 26.115-108.39 65.909-17.57 33.529-18.465 75.207-1.997 109.631 16.49 34.468 49.813 60.459 87.545 67.698 38.305 7.348 79.732-4.704 108.15-31.961 23.86-22.885 37.914-55.558 37.808-88.958-.164-51.631-35.567-101.55-87.322-117.135-11.318-3.408-23.141-5.127-34.997-5.184-.265 0-.531 0-.797 0z"
        fillRule="nonzero"
      />
    </g>
  </Icon>
);

export default Error;

const Icon = styled.svg``;
