const setItem = (key, value) => {
  try {
    return typeof sessionStorage !== "undefined" && sessionStorage.setItem(key, value);
  } catch (_) {
    return {};
  }
};
const getItem = (key) => {
  try {
    return typeof sessionStorage !== "undefined" ? sessionStorage.getItem(key) : undefined;
  } catch (_) {
    return {};
  }
};

const removeItem = (key) => {
  try {
    return typeof sessionStorage !== "undefined" && sessionStorage.removeItem(key);
  } catch (_) {
    return {};
  }
};

export default {
  setIsUniversalPageFlow: (value) => setItem("isUniversalPageFlow", value),
  getIsUniversalPageFlow: () => getItem("isUniversalPageFlow"),
  removeIsUniversalPageFlow: () => removeItem("isUniversalPageFlow"),
  removeIsFromLogin: () => removeItem("isFromLogin"),
  setSomeLoginFlow: (provider) => setItem("someLogin", provider),
  getSomeLoginFlow: () => getItem("someLogin"),
  removeSomeLoginFlow: () => removeItem("someLogin"),
  setOriginUrl: (url) => setItem("originUrl", url),
  getOriginUrl: () => getItem("originUrl"),
  setSource: (source) => setItem("evtSrc", source),
  getSource: () => getItem("evtSrc"),
  setType: (type) => setItem("type", type),
  getType: () => getItem("type"),
  setLoginState: (state) => setItem("loginState", state),
  getLoginState: () => getItem("loginState"),
  removeLoginState: () => removeItem("loginState"),
  setLang: (state) => setItem("lang", state),
  getLang: () => getItem("lang"),
  removeLang: () => removeItem("lang"),
  removeSource: () => removeItem("evtSrc"),
  removeOriginUrl: () => removeItem("originUrl"),
};
