import { useEffect, useRef, useState } from "react";

export default () => {
  const [focused, setFocused] = useState(false);

  const onInputFocus = useRef();
  const onInputBlur = useRef();

  useEffect(() => {
    let timeout;
    onInputFocus.current = () => {
      clearTimeout(timeout);
      if (!focused) setFocused(true);
    };
    onInputBlur.current = () => {
      timeout = setTimeout(() => setFocused(false), 0);
    };
    return () => clearTimeout(timeout);
  }, []);

  return [focused, () => onInputBlur.current(), () => onInputFocus.current()];
};
