import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { LinkFocusStyle } from "./Focus";
import { TextBold, TextRegular, TextSemiBoldInheritSize, TextSmall, TextLarge } from "./Text";

const P = ({ children, paragraphContent, ...rest }) => {
  return paragraphContent ? (
    <Paragraph dangerouslySetInnerHTML={{ __html: paragraphContent }} {...rest} />
  ) : (
    <Paragraph {...rest}>{children}</Paragraph>
  );
};

P.defaultProps = {
  children: null,
  paragraphContent: null,
  leftAlign: false,
  centerAlign: false,
};

P.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node, PropTypes.object]),
  paragraphContent: PropTypes.string,
  leftAlign: PropTypes.bool,
  centerAlign: PropTypes.bool,
};

export default P;

// STYLED COMPONENT
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
const alertStyle = (props) => `
  color: ${props.theme.fontColorError};
  line-height: 1.2;
`;

const successStyle = (props) => `
color: ${props.theme.fontColorSuccess};
line-height: 1.2;`;

const getRoleStyles = (role) => {
  switch (role) {
    case "alert":
      return TextSmall && alertStyle;
    case "success":
      return TextBold(TextLarge) && successStyle;
    default:
      return TextRegular;
  }
};

const Paragraph = styled.p`
  ${(props) => getRoleStyles(props.role)}
  ${(props) => (props.leftAlign ? "text-align: left;" : "")}
  ${(props) => (props.centerAlign ? "text-align: center;" : "")}
  margin: 0 0 10px 0;
  padding: 0;
  width: 100%;
  > a,
  * > a {
    color: ${(props) => props.theme.linkColor || "black"} !important;
    text-decoration: underline;
    ${LinkFocusStyle}
    &:hover {
      color: ${(props) => props.theme.linkColor || "black"} !important;
      text-decoration: underline;
    }
  }
  strong,
  b {
    ${TextSemiBoldInheritSize}
  }
  button {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const PBold = styled(P)`
  ${({ role }) => (role === "alert" ? TextBold(TextSmall) && alertStyle : TextBold(TextRegular))}
`;

export const PWithButton = styled(P)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
