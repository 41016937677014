// Define what props.theme will look like
const themeSettings = {
  backgroundColor: "#AAA",
  darkGray: "#4A4A4A",
  mediumGray: "#666666",
  almaDarkPurple: "#A04191",
  almaLightPurple: "#D2B9DC",

  // Border
  borderColorBase: "rgb(74,74,74)",
  borderColorDisabled: "#CCC",
  borderColorError: "#f00",

  // Font
  fontTypeBase: "GalanoGrotesqueAlt-Regular",
  fontTypeMedium: "GalanoGrotesqueAlt-Medium",
  fontTypeSemiBold: "GalanoGrotesqueAlt-SemiBold",
  fontTypeSystemUI: "system-ui",

  // Font Colors
  fontColorBase: "#4a4a4a",
  fontColorDisabled: "#CCC",
  fontColorError: "#f00",
  fontColorSuccess: "#036702",

  fontTiny: "12px",
  fontSmall: "13px",
  fontNormal: "14px",
  fontLarge: "16px",
  fontLarger: "18px",
  fontLargest: "20px",

  // Breakpoints
  breakpoints: {
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "450px",
  },

  // Buttons
  button: {
    primary: "#93B91F",
    secondary: "#13778B",
    default: "#A04191",
  },
};

export default themeSettings;
