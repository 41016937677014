import React from "react";
import styled from "styled-components";

const Sinuna = () => (
  <Icon
    width="25px"
    height="25px"
    clip-rule="evenodd"
    fill-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
    viewBox="0 0 953 953"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#fff">
      <path d="m0 0v119.076h833.424v833.424h119.076v-952.5z" />
      <path d="m476.25 476.25v476.25h-119.073v-238.125c0-65.752-53.3-119.049-119.052-119.049-65.748 0-119.049 53.297-119.049 119.049v238.125h-119.076v-476.25z" />
      <path d="m0 240v118.72h596.865v593.78h119.385v-712.5z" />
    </g>
  </Icon>
);

export default Sinuna;

const Icon = styled.svg``;
