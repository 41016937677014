import PropTypes from "prop-types";
import React, { useState } from "react";

import Error from "./ErrorNotification";
import RegisterComplete from "./RegisterComplete";
import VerifyEmail from "./VerifyEmailNotification";

const SignupNotification = ({ user }) => {
  const [state, setState] = useState({ view: 0, error: {} });
  switch (state.view) {
    case 1:
      return <VerifyEmail user={user} />;
    case 2:
      return <Error errorMessage={state.error} />;
    default:
      return <RegisterComplete setState={setState} user={user} />;
  }
};

SignupNotification.propTypes = {
  user: PropTypes.object.isRequired,
};

export default SignupNotification;
