import localStore from "../storageAPIs/localStore";

export default (idToken, loginResult) => {
  if (idToken && loginResult) {
    localStore.setIdTokenData({
      audience: idToken.aud,
      issuer: idToken.iss,
      nonce: idToken.nonce,
      idToken: loginResult.idToken,
      verified: idToken.email_verified,
    });
  }
};
