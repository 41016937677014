import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { PrimaryButton } from "../components/Buttons";
import { Container } from "../components/Containers";
import { H1 } from "../components/Headers";
import IconError from "../components/icons/Error";
import P from "../components/P";
import { useUIContext } from "../providers/UIContextProvider";
import NotificationFrame from "./NotificationFrame";

const ErrorNotification = ({ closeNotification }) => {
  const { t } = useUIContext();
  return (
    <Container role="alert">
      <IconError />
      <H1custom id="notification-title">{t("notifications.resendVerificationError.heading")}</H1custom>
      <Container id="description">
        <P dangerouslySetInnerHTML={{ __html: t("notifications.resendVerificationError.description") }} />
        <PrimaryButtonCustom type="button" onClick={closeNotification}>
          {t("common.labelClose")}
        </PrimaryButtonCustom>
      </Container>
    </Container>
  );
};

ErrorNotification.propTypes = {
  closeNotification: PropTypes.func.isRequired,
};

const H1custom = styled(H1)`
  margin: 20px auto 15px auto;
  max-width: 310px;
`;

const PrimaryButtonCustom = styled(PrimaryButton)`
  margin-top: 20px;
`;

export default NotificationFrame(ErrorNotification);
