import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { useUIContext } from "../providers/UIContextProvider";
import { generateText } from "../utils/text-tag-utils";
import { Container } from "./Containers";
import { LinkFocusStyle } from "./Focus";
import { H2 } from "./Headers";
import Bullet from "./icons/Bullet";
import P from "./P";
import { TextRegular, TextSemiBoldInheritSize } from "./Text";

const Bullets = ({ page, Button }) => {
  const { assets, core, t } = useUIContext();
  const bulletListAssets = page ? t(`pages.${page}.bulletList`, true) || [] : [];

  const bulletListElements = Array.isArray(bulletListAssets) ? bulletListAssets.map((e) => e.text) : [];
  const bulletList = bulletListElements.map((text, i) => generateText(text, i, core, assets)) || bulletListElements;
  const heading = t(`pages.${page}.bulletListHeading`, true);
  const desc = t(`pages.${page}.bulletListDescription`, true);
  const additionalInfo = t(`pages.${page}.additionalInfo`, true);

  return (
    <>
      {page && (
        <TopContainer>
          {heading && <H2 dangerouslySetInnerHTML={{ __html: heading }} />}
          {Button && <Button />}
          {desc && <P dangerouslySetInnerHTML={{ __html: desc }} leftAlign={bulletListElements.length > 0} />}
        </TopContainer>
      )}
      {bulletListElements.length > 0 && (
        <BulletList role="list">
          {bulletList.map((listItem, index) => {
            return (
              <li key={index}>
                <Bullet />
                <div role="listitem" dangerouslySetInnerHTML={{ __html: listItem }} />
              </li>
            );
          })}
        </BulletList>
      )}
      {additionalInfo && (
        <BulletBottomDescription>
          <P paragraphContent={additionalInfo} leftAlign />
        </BulletBottomDescription>
      )}
    </>
  );
};

Bullets.defaultProps = {
  page: null,
  Button: null,
};

Bullets.propTypes = {
  page: PropTypes.string,
  Button: PropTypes.func,
};

export default Bullets;

// STYLED COMPONENT
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

const TopContainer = styled(Container)`
  text-align: center;
`;

const BulletList = styled.ul`
  ${TextRegular}
  margin: 15px 0 25px 0;
  width: 100%;
  list-style: none;
  padding: 0;
  li {
    display: table;
    vertical-align: middle;
    text-align: left;
    div,
    svg {
      display: table-cell;
    }
    svg {
      margin: 0 4px 0 0;
      vertical-align: middle;
      width: 14px;
      height: 14px;
    }
    a,
    div > a {
      color: ${(props) => props.theme.linkColor};
      text-decoration: underline;
      ${LinkFocusStyle}
      &:hover {
        color: ${(props) => props.theme.linkColor};
        text-decoration: underline;
      }
    }
    strong,
    b {
      ${TextSemiBoldInheritSize}
    }
  }
  li + li {
    margin-top: 20px;
  }
`;

const BulletBottomDescription = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;
