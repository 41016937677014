export default (config) => {
  const basicLogging =
    false ||
    typeof window === "undefined" ||
    (typeof document !== "undefined" && !!document.documentMode) ||
    window.navigator.userAgent.indexOf("Edge") > -1;

  const withAppName = (message) => `[ALMA-TUNNUS] ${message}`;

  const log = (...message) =>
    basicLogging ? console.log(withAppName(message)) : console.log(`%c${withAppName(message)}`, `color: #a04191`);

  const logGATitle = (message) =>
    basicLogging
      ? console.log(withAppName(message))
      : console.log(`%c${withAppName(message)}`, "background: blue; color: white; padding: 4px");

  const assert = (value, message) => console.assert(value, withAppName(message));
  const error = (message) => console.error(withAppName(message));

  const noop = () => {};
  const debug = config.debugMode;
  return {
    log: debug ? log : noop,
    logGATitle: debug ? logGATitle : noop,
    assert: debug ? assert : noop,
    error: debug ? error : noop,
  };
};
