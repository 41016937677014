// Decode URL params to object
const decodeURLParams = (search) => {
  const hashes = search.slice(search.indexOf("?") + 1).split("&");
  return hashes.reduce((params, hash) => {
    const split = hash.indexOf("=");
    const key = hash.slice(0, split);
    const val = hash.slice(split + 1);
    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
};

const assetIsDefined = (assets) => (assetKey) => assets[assetKey] && assets[assetKey] !== "";
export const assetsAreDefined = (assets, ...assetKeys) => assetKeys.every(assetIsDefined(assets));

export const isUsingWebflow = () => typeof document !== "undefined" && document.querySelector("[data-wf-page]");

export default decodeURLParams;

export const isBlack = (color) =>
  color === "#000" ||
  color === "#000000" ||
  color === "rgb(0, 0, 0)" ||
  color === "rgba(0, 0, 0, 1)" ||
  color === "hsl(0, 0%, 0%)" ||
  color === "black";

const android = /Android/i;
const mobileAgents = [
  android,
  /BlackBerry/i,
  /iPhone|iPod/i,
  /Opera Mini/i,
  /IEMobile|WPDesktop|Windows Phone/i,
  /webOS/i,
];

export const isAndroid = () => navigator.userAgent.match(android);
export const isMobile = () => mobileAgents.some((agent) => navigator.userAgent.match(agent));
