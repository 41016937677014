const cmpAvailableEvent = "alma_cmp_v2_consents_available_repetitive";
const userOptedInEvent = "alma_cmp_v2_has_optedin";
const b2bCmpAvailableEvent = "alma_b2b_consent_available_repetitive";

const facebookRequiredConsents = {
  standard: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  specialFeature: [1, 2],
  nonTCFVendors: [1],
};

const appleRequiredConsents = {
  standard: [1],
  nonTCFVendors: [27],
};

const LinkedinRequiredConsents = {
  standard: [1],
  nonTCFVendors: [15],
};

const GoogleRequiredConsents = {
  standard: [1],
  nonTCFVendors: [3],
};

const GoogleAnalyticsRequiredConsents = {
  standard: [1, 8],
  nonTCFVendors: [3],
};

export const defaultCMPData = {
  standard: false,
  facebook: false,
  google: false,
  ga: false,
  linkedin: false,
  apple: false,
};

const hasRequiredConsents = (consentData, required) => {
  // check standard consents are ok if required
  if (Array.isArray(required?.standard) && required.standard.length > 0) {
    if (!required?.standard?.every((id) => consentData?.standard?.[id])) {
      return false;
    }
  }
  // check special feature consents are ok if required
  if (Array.isArray(required?.specialFeature) && required.specialFeature.length > 0) {
    if (!required.specialFeature.every((id) => consentData?.specialFeature?.[id])) {
      return false;
    }
  }
  // check non tcf vendor consents are ok if required
  if (Array.isArray(required?.nonTCFVendors) && required.nonTCFVendors.length > 0) {
    if (!required.nonTCFVendors.every((id) => consentData?.nonTCFVendors?.[id])) {
      return false;
    }
  }
  return true;
};

const hasStdConsent = (consentData) => !!consentData?.standard?.[1];

const hasFbConsent = (consentData) => hasRequiredConsents(consentData, facebookRequiredConsents);
const hasAppleConsent = (consentData) => hasRequiredConsents(consentData, appleRequiredConsents);
const hasLinkedinConsent = (consentData) => hasRequiredConsents(consentData, LinkedinRequiredConsents);
const hasGoogleConsent = (consentData) => hasRequiredConsents(consentData, GoogleRequiredConsents);
const hasGAConsent = (consentData) => hasRequiredConsents(consentData, GoogleAnalyticsRequiredConsents);

const hasB2BConsent = (b2b) => !!b2b;

export const getCMPData = (consentData) => ({
  standard: hasStdConsent(consentData),
  facebook: hasFbConsent(consentData),
  google: hasGoogleConsent(consentData),
  ga: hasGAConsent(consentData),
  linkedin: hasLinkedinConsent(consentData),
  apple: hasAppleConsent(consentData),
});

export const getB2BCMPData = async (b2b) => hasB2BConsent(b2b);
export const getB2B = async (consentData) => consentData?.detail;
export const processCmpData = (data) => Promise.resolve(getCMPData(data?.detail));
export const b2bProcessCmpData = (details) => Promise.resolve(getB2B(details));
export const handleCMPEvent = (cb) => (details) => processCmpData(details).then(cb);

export const handleB2BCMPEvent = (cb) => (details) => b2bProcessCmpData(details).then(cb);
export const addUserOptedInListener = (cb) => window.addEventListener(userOptedInEvent, handleCMPEvent(cb), false);
export const addB2BCMPListener = (cb) => {
  const b2bHandleEvent = (details) => {
    window.removeEventListener(b2bCmpAvailableEvent, b2bHandleEvent, false);
    handleB2BCMPEvent(cb)(details);
  };

  window.addEventListener(b2bCmpAvailableEvent, b2bHandleEvent, false);
};

export default (cb) => {
  const handleEvent = (details) => {
    window.removeEventListener(cmpAvailableEvent, handleEvent, false);
    handleCMPEvent(cb)(details);
  };

  window.addEventListener(cmpAvailableEvent, handleEvent, false);
};
