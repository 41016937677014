import PropTypes from "prop-types";
import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";

import AlertText from "../../components/AlertText";
import { ButtonFocusStyle } from "../../components/Focus";
import { GoogleButton, TextSomeButton } from "../../components/Text";
import useInputFocus from "../../hooks/useInputFocus";
import { isBlack } from "../../utils/helper-functions";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const focus = css`
  ${ButtonFocusStyle}
`;

const SomeLoginButton = styled.button`
  ${TextSomeButton}
  display: ${({ loaded }) => (loaded ? "flex" : "none")};
  ${({ isFocus }) => isFocus && focus}
  // animation: ${fadeIn} 0.2s ease-in;
  align-items: center;
  line-height: 26px;
  height: 45px;
  width: 100%;
  max-width: 270px;
  min-width: 255px;
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  > span {
    flex-grow: 1;
    padding-right: 10px;
  }
  > svg {
    flex-shrink: 0;
  }
  &:active,
  &:focus {
    ${focus}
  }
  &:hover {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), inset 0 0 0 30px ${(props) =>
      props.theme.buttonColor && isBlack(props.theme.buttonColor)
        ? "rgba(255, 255, 255, 0.17)"
        : "rgba(0, 0, 0, 0.17)"};
  }
  ${({ providerConf }) => providerConf}
`;

const GoogleLoginButton = styled(SomeLoginButton)`
  ${GoogleButton}
`;

const DisabledButton = styled(SomeLoginButton)`
  position: relative;
  overflow: hidden;
  box-shadow: none;
  &:active,
  &:focus {
    ${focus}
    cursor: not-allowed;
  }
  &:hover {
    cursor: not-allowed;
    box-shadow: none;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(255 255 255 / 60%);
  }
`;

const SomeButton = ({ loginText, icon: Icon, className, consentMessage, ...rest }) => {
  const iconIsComponent = typeof Icon === "function";
  const [loaded, setLoaded] = useState(iconIsComponent);
  const [isFocus, onBlur, onFocus] = useInputFocus();
  const onLoad = () => setLoaded(true);

  const isGoogleButton = className === "alma-tunnus-social-media-login-google-oauth2";

  return consentMessage ? (
    <>
      <DisabledButton
        type="button"
        tabIndex="0"
        {...rest}
        onClick={null}
        loaded={loaded}
        onFocus={onFocus}
        onBlur={onBlur}
        isFocus={isFocus}
        className={className}
        aria-disabled="true"
        aria-describedby={className + "-disabled-error-message"}
      >
        {iconIsComponent ? <Icon /> : <img alt="" role="presentation" src={Icon} onLoad={onLoad} />}
        <span dangerouslySetInnerHTML={{ __html: loginText }} />
      </DisabledButton>
      <AlertText id={className + "-disabled-error-message"}>{consentMessage}</AlertText>
    </>
  ) : isGoogleButton ? (
    <GoogleLoginButton
      type="button"
      tabIndex="0"
      {...rest}
      loaded={loaded}
      onFocus={onFocus}
      onBlur={onBlur}
      isFocus={isFocus}
      className={className}
    >
      {iconIsComponent ? <Icon /> : <img alt="" role="presentation" src={Icon} onLoad={onLoad} />}
      <span dangerouslySetInnerHTML={{ __html: loginText }} />
    </GoogleLoginButton>
  ) : (
    <SomeLoginButton
      type="button"
      tabIndex="0"
      {...rest}
      loaded={loaded}
      onFocus={onFocus}
      onBlur={onBlur}
      isFocus={isFocus}
      className={className}
    >
      {iconIsComponent ? <Icon /> : <img alt="" role="presentation" src={Icon} onLoad={onLoad} />}
      <span dangerouslySetInnerHTML={{ __html: loginText }} />
    </SomeLoginButton>
  );
};

SomeButton.defaultProps = {
  consentMessage: null,
};

SomeButton.propTypes = {
  loginText: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  consentMessage: PropTypes.string,
};

export default SomeButton;
