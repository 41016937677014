export default () => {
  const events = [];
  const eventStore = [];

  // hasOwnProperty
  const hOP = (event) => Object.prototype.hasOwnProperty.call(events, event);

  return {
    subscribe: (event, callback) => {
      if (!hOP(event)) events[event] = [];
      events[event].push(callback);
    },

    unsubscribe: (event, callback) => {
      events[event] = events[event].filter((cb) => cb !== callback);
    },

    publish: (event, data = {}) => {
      if (hOP(event)) return events[event].map((callback) => callback(data));
      return eventStore.push({ event, data });
    },

    latePublish: () => {
      if (eventStore.length !== 0) {
        eventStore.forEach((e) => (hOP(e.event) ? events[e.event].map((callback) => callback(e.data)) : []));
        eventStore.length = 0;
      }
    },
  };
};
