import React from "react";
import styled from "styled-components";

import { LinkButton } from "../../components/Buttons";
import P from "../../components/P";
import { useModalStageContext } from "../../providers/modal/ModalStageContext";
import { useUIContext } from "../../providers/UIContextProvider";

const LinkTextContents = styled(P)`
  margin: 30px 0 30px 0;
  text-align: center;
  > * {
    margin-top: -4px;
  }
`;

export default (props) => {
  const { t } = useUIContext();
  const { setModalStage } = useModalStageContext();

  const [backButtonFrontText, backButtonLinkText, backButtonEndText] = t("common.goToLoginText").split("#");
  const backToMainPage = () => setModalStage("LOGIN");

  return (
    <LinkTextContents>
      {backButtonFrontText}
      <LinkButton {...props} onClick={backToMainPage} tabIndex={0}>
        {backButtonLinkText}
      </LinkButton>
      {backButtonEndText}
    </LinkTextContents>
  );
};
