import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import AlertMessage from "../../components/AlertMessage";
import Bullets from "../../components/Bullets";
import { LinkButtonSmall, LinkButtonSmallStyle, PrimaryButton, StyledButton } from "../../components/Buttons";
import { Container } from "../../components/Containers";
import { H2 } from "../../components/Headers";
import Input from "../../components/input/Input";
import InputPassword from "../../components/input/InputPassword";
import P from "../../components/P";
import { useModalStageContext } from "../../providers/modal/ModalStageContext";
import { useUIContext } from "../../providers/UIContextProvider";
import AltLoginMethods from "./AltLoginMethods";

const Login = () => {
  const { core, disableSignUp, t } = useUIContext();
  const getErrorMessage = (e) => ({ error: e.code || e.error, text: t(`errors.${e.code || e.error}`) });
  const getErrorMessages = (errors) => errors.map(getErrorMessage);
  const { setModalStage, modalData } = useModalStageContext();
  const getInitialErrorMessages = () => {
    if (!navigator.cookieEnabled) return [getErrorMessage({ code: "paranoid" })];
    else return null;
  };

  const [state, setState] = useState({
    errors: getInitialErrorMessages(),
    passwordFieldHasBeenWebkitAutofilled: false,
    performingLogin: false,
    showMoreMethods: false,
  });
  const usernameInput = useRef("");
  const passwordInput = useRef("");

  const catchAutoFill = (e) =>
    e.animationName === "alma-tunnus-component-animation--on-auto-fill-start"
      ? setState({ ...state, passwordFieldHasBeenWebkitAutofilled: true })
      : false;

  const handleLogin = async () => {
    setState({ ...state, performingLogin: true });
    return core
      .login(usernameInput.current.value, passwordInput.current.value, state.update_allowed_silent)
      .then((result) => {
        result.errors
          ? setState({ ...state, performingLogin: false, errors: getErrorMessages(result.errors) })
          : setModalStage(-1);
      });
  };

  const onRegisterClick = () => setModalStage("REGISTER");
  const onKeyPress = ({ which, keyCode }) => {
    if (which === 13 || keyCode === 13) {
      if (usernameInput.current.value === "" && passwordInput.current.value !== "") usernameInput.current.focus();
      else handleLogin();
    } else setState({ ...state, error: null });
  };
  const onResetPasswordClick = () => {
    const username = usernameInput.current.value;
    const resetUrl = t("pages.login.resetPasswordLink", true);
    return resetUrl
      ? window.open(core.getPasswordResetUrl(resetUrl, username))
      : setModalStage("RESET", core.getEmailValidity(username) ? { username } : {});
  };

  const paranoid = state.errors?.some((e) => e.error === "paranoid");

  useEffect(() => {
    usernameInput.current.focus();
  }, []);
  const highlightSignup = !!t("settings.highlightSignup", true);
  const hasRegisterLabel = !!t("pages.login.labelNoAlmaTunnus", true);

  const AdditionalRegisterLink = () => {
    const regUrl = t("pages.login.additionalRegisterLinkUrl");
    const clickHandler = () => (regUrl ? window.open(regUrl) : onRegisterClick());
    return <LinkButtonSmall onClick={clickHandler}>{t("pages.login.additionalRegisterLinkLabel")}</LinkButtonSmall>;
  };

  const HighlightedSignup = () => {
    if (!highlightSignup) return null;
    const RegisterButton = hasRegisterLabel
      ? () => <PrimaryButton onClick={onRegisterClick}>{t("pages.login.labelNoAlmaTunnus")}</PrimaryButton>
      : null;
    return (
      <HighlightedSignupContainer>
        <Bullets page="login" Button={RegisterButton} />
      </HighlightedSignupContainer>
    );
  };

  return (
    <ColumnContainer role="dialog" aria-describedby="description" aria-labelledby="alma-tunnus-title">
      <H2custom id="alma-tunnus-title" dangerouslySetInnerHTML={{ __html: t("pages.login.heading") }} />
      {t("pages.login.description") && (
        <P
          id="description"
          style={{ textAlign: "center" }}
          dangerouslySetInnerHTML={{ __html: t("pages.login.description") }}
        />
      )}
      <FormFields onSubmit={handleLogin} noValidate>
        <Input
          defaultValue={modalData.username || modalData.email || ""}
          label={t("pages.login.labelEmail")}
          id="alma-tunnus-username"
          name="username"
          autoComplete="username"
          aria-label={t("pages.login.labelEmail")}
          type="email"
          tabIndex="0"
          ref={usernameInput}
          onKeyPress={onKeyPress}
        />
        <InputPassword
          label={t("passwordInput.label")}
          aria-label={t("passwordInput.label")}
          id="alma-tunnus-password"
          name="password"
          autoComplete="current-password"
          tabIndex="0"
          ref={passwordInput}
          onKeyPress={onKeyPress}
          onAnimationStart={catchAutoFill}
        />
      </FormFields>
      {state.errors && (
        <AlertMessage>
          <P role="alert" dangerouslySetInnerHTML={{ __html: state.errors[0].text }} />
        </AlertMessage>
      )}
      {!paranoid && (
        <>
          <PrimaryButton
            type="submit"
            tabIndex="0"
            title={t("pages.login.buttonLoginTitle")}
            id="alma-tunnus-button-login"
            onClick={handleLogin}
            performingAction={state.performingLogin}
          >
            {t("pages.login.buttonLogin")}
          </PrimaryButton>

          <LinksContainer>
            <LinkButtonSmall onClick={onResetPasswordClick}>{t("common.labelResetPassword")}</LinkButtonSmall>
            {!disableSignUp && !highlightSignup && hasRegisterLabel && (
              <LinkButtonSmall onClick={onRegisterClick}>{t("pages.login.labelNoAlmaTunnus")}</LinkButtonSmall>
            )}

            {t("pages.login.additionalRegisterLinkLabel", true) && <AdditionalRegisterLink />}
          </LinksContainer>

          <AltLoginMethods />

          <HighlightedSignup />
        </>
      )}
    </ColumnContainer>
  );
};

export default Login;

// STYLED COMPONENT
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

const HighlightedSignupContainer = styled(Container)`
  margin-top: 25px;
  ${StyledButton} {
    margin-top: 5px;
    margin-bottom: 25px;
  }
`;

const LinksContainer = styled(Container)`
  ${LinkButtonSmallStyle} {
    margin-top: 20px;
    & + ${LinkButtonSmallStyle} {
      margin-top: 8px;
    }
  }
`;

const ColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  min-height: 475px;
`;

const H2custom = styled(H2)`
  margin-bottom: 10px;
`;

const FormFields = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
  > div {
    margin-top: 10px;
  }
`;
