import localStore from "../storageAPIs/localStore";
import sessionStore from "../storageAPIs/sessionStore";
import addLoginAnalytics from "./addLoginAnalytics";
import isPasswordlessFlow from "./isPasswordlessFlow";
import storeToken from "./storeToken";

export const getParseParams = (config) => async (hash) => {
  const parseParams = {};
  if (isPasswordlessFlow()) {
    parseParams.state = window.localStorage.getItem("state");
    parseParams.nonce = window.localStorage.getItem("nonce");
    window.localStorage.removeItem("state");
    window.localStorage.removeItem("nonce");
  }
  parseParams.hash = hash || config?.responseHash;
  return parseParams;
};

export const checkUserHash = (logger, events, webAuth) => (parseParams) =>
  new Promise((resolve, reject) => {
    webAuth.parseHash(parseParams, (error, success) => (error ? reject(error) : resolve(success)));
  }).catch((e) => {
    if (e?.error) {
      logger.error(`URL check error: ${JSON.stringify(e)}`, "warning");
      if (e.error === "login_required") {
        localStore.removeIdTokenData();
      } else {
        if (e.error === "unauthorized" || e.errorDescription === "unverified_users_not_allowed") {
          events.publish("tunnusErrorEvent", { ...e, pwdless: isPasswordlessFlow() });
        }
        return { error: e };
      }
      sessionStore.removeOriginUrl();
    }
    return false;
  });

export const initSessionFromHashResult = (config, state, loginFunctions, funcs, originUrl) => (hashResult) => {
  if (hashResult?.error) {
    let error = hashResult?.error;
    return {
      cb: () => config.loginCallback({ ...error, originUrl }, null),
      hashInitResult: null,
      newLogin: false,
    };
  }
  const oldIdToken = localStore.getIdTokenData();
  const { idToken, idTokenPayload } = hashResult;
  const hashInitResult = loginFunctions.setUserSession(idTokenPayload, !oldIdToken);
  let cb;
  let newLogin;

  storeToken(idToken, hashResult);
  if (oldIdToken !== null && typeof oldIdToken !== "undefined") {
    const idToken = hashResult?.idToken || localStore.getIdTokenData().idToken;
    cb = () => config.checkSessionCallback(hashInitResult, idToken, hashResult.accessToken);
  } else {
    newLogin = true;
    const someProvider = idTokenPayload["https://almamedia.net/connection"];
    cb = addLoginAnalytics(
      config,
      state,
      funcs,
      someProvider
    )(hashInitResult, () => {
      config.loginCallback(null, {
        accessToken: hashResult.accessToken,
        user: hashInitResult,
        idToken: hashResult.idToken,
        originUrl,
        state: hashResult.state,
      });
    });
  }
  sessionStore.removeOriginUrl();
  return { cb, hashInitResult, newLogin };
};
