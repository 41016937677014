import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useReducer } from "react";

import fetchConfiguration from "./fetchExternalConfiguration";
import Translator from "./translator";
import reducer, { initialState } from "./UIContextReducer";

export const UIContext = createContext();
export const useUIContext = () => useContext(UIContext);

export const UIContextProvider = (props) => {
  const { clientID, core, debugMode, domain, lng, forceKeys } = props;
  const { loginNotification, disableSignUp, logoutPopup } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const t = Translator(state.assets, forceKeys ? forceKeys : state.forceKeys, core);

  useEffect(() => {
    const getConfsDomain = () => (core.isProd ? "tunnus.almamedia.fi" : "tunnus-dev.almamedia.net");
    const confsDomain = /almamedia(-dev|-test)?\.eu\.auth0\.com/.test(domain) ? getConfsDomain() : domain;
    fetchConfiguration(confsDomain, clientID, lng).then((loadedAssets) => {
      core.analytics.initSiteAnalytics(loadedAssets?.settings?.customAnalytics);
      dispatch({ type: "assetsLoaded", payload: loadedAssets });
    });
  }, [clientID, lng, fetchConfiguration]);

  useEffect(() => {
    if (debugMode)
      window.ALMA.tunnus = {
        ...window.ALMA.tunnus,
        forceKeys: (force) => dispatch({ type: "forceKeys", payload: force }),
      };
    const setUser = ({ userData }) => dispatch({ type: "setUser", payload: userData });
    const setError = (errorData) => dispatch({ type: "setError", payload: errorData });

    const events = core.getEvents();
    events.subscribe("tunnusUserEvent", setUser);
    events.subscribe("tunnusErrorEvent", setError);
    events.latePublish();
    return () => {
      events.unsubscribe("tunnusUserEvent", setUser);
      events.unsubscribe("tunnusErrorEvent", setError);
    };
  }, []);

  useEffect(() => {
    if (loginNotification && !state.assetsLoading && (state?.user?.fromLoginEvent || state.error))
      dispatch({ type: "renderNotification", payload: true });
  }, [state.user, state.error, state.assetsLoading]);

  const { children } = props;
  return (
    <UIContext.Provider
      value={{
        core,
        user: state.user,
        assets: state.assets,
        assetsLoading: state.assetsLoading,
        disableSignUp,
        error: state.error,
        shouldRenderNotification: state.shouldRenderNotification,
        notificationClosing: state.notificationClosing,
        t,
        dispatch,
        logoutPopup,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

UIContextProvider.defaultProps = {
  debugMode: false,
  domain: null,
  clientID: null,
  lng: "fi",
  loginNotification: true,
  disableSignUp: false,
  forceKeys: null,
  logoutPopup: null,
};

UIContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
  core: PropTypes.object.isRequired,
  debugMode: PropTypes.bool,
  domain: PropTypes.string,
  clientID: PropTypes.string,
  lng: PropTypes.string,
  loginNotification: PropTypes.bool,
  disableSignUp: PropTypes.bool,
  forceKeys: PropTypes.bool,
  logoutPopup: PropTypes.object,
};
