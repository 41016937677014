import emptyUser from "./emptyUser";
import formatUserData from "./formatUserData";

const UserStore = (events) => {
  let user = emptyUser;
  return {
    getUser: () => user,

    setUser: (idToken, fromLoginEvent) => {
      user = { ...formatUserData(idToken), fromLoginEvent };
      events.publish("tunnusUserEvent", { userData: user });
    },

    setUserAttribute: (key, value) => {
      user[key] = value;
    },

    clearUser: () => {
      user = emptyUser;
      events.publish("tunnusUserEvent", { userData: user });
    },
  };
};

export default UserStore;
