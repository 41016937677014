import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { TextSmall } from "../../components/Text";
import { useUIContext } from "../../providers/UIContextProvider";
import ChecklistSymbol from "./ChecklistSymbol";

const ChecklistContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 0 !important;
`;

const ChecklistDescription = styled.p`
  ${TextSmall}
  margin: 0;
  color: ${({ ok }) => (ok === false ? "#FF0000" : "#000000")};
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  color: ${({ ok }) => (ok ? "black" : "inherit")};
`;

const Checklist = styled.ul`
  ${TextSmall}
  width:100%;
  list-style: none;
  align-self: flex-start;
  margin: 0;
  padding: 0;
  color: ${({ ok }) => (ok === false ? "#FF0000" : "#000000")};
`;

const TextContainer = styled.span`
  color: ${({ ok }) => (ok ? "#929292" : "inherit")};
`;

const ListItem = ({ ok, focus, children }) => (
  <Item ok={ok}>
    <ChecklistSymbol ok={ok} focus={focus} />
    <TextContainer ok={ok} dangerouslySetInnerHTML={{ __html: children }} />
  </Item>
);

ListItem.defaultProps = {
  ok: undefined,
  focus: false,
};

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  ok: PropTypes.bool,
  focus: PropTypes.bool,
};

const PasswordChecklist = ({ pwdValidities, pwdHasFocus, allOK, id }) => {
  const { t } = useUIContext();
  const [lettersOK, charactersOK, lengthOK] = pwdValidities;
  const checkOk = allOK && (pwdHasFocus || (lettersOK && charactersOK && lengthOK));
  return (
    <ChecklistContainer id={id}>
      <ChecklistDescription ok={checkOk}>{t("passwordInput.checklist.desc")}</ChecklistDescription>
      <Checklist ok={checkOk}>
        <ListItem ok={lettersOK} focus={pwdHasFocus}>
          {t("passwordInput.checklist.letters")}
        </ListItem>
        <ListItem ok={charactersOK} focus={pwdHasFocus}>
          {t("passwordInput.checklist.numbersSymbols")}
        </ListItem>
        <ListItem ok={lengthOK} focus={pwdHasFocus}>
          {t("passwordInput.checklist.length")}
        </ListItem>
      </Checklist>
    </ChecklistContainer>
  );
};

PasswordChecklist.defaultProps = {
  pwdValidities: [],
  pwdHasFocus: false,
  allOK: true,
};

PasswordChecklist.propTypes = {
  pwdValidities: PropTypes.array,
  pwdHasFocus: PropTypes.bool,
  allOK: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

export default PasswordChecklist;
