import "../polyfills";

import React, { useEffect } from "react";

import EmbeddedCore from "../core/Embedded/EmbeddedCore";
import ModalStageProvider from "./providers/modal/EmbedStageProvider";
import StyleProvider from "./providers/StyleProvider";
import { UIContextProvider } from "./providers/UIContextProvider";
import { preloadAlmaFonts } from "./utils/font-manager";
import ViewRoot from "./ViewRoot";

const Tunnus = (props) => {
  const core = EmbeddedCore({ waitForLoad: true, ...props });

  useEffect(() => preloadAlmaFonts(), []);

  return (
    <UIContextProvider {...props} core={core}>
      <StyleProvider>
        <ModalStageProvider>
          <ViewRoot {...props} />
        </ModalStageProvider>
      </StyleProvider>
    </UIContextProvider>
  );
};

export default Tunnus;
