// Validating border color
const styleIsValidBorder = ({ theme, disabled, isValid }) => {
  if (disabled) {
    return theme.borderColorDisabled;
  }
  return isValid ? theme.borderColorBase : theme.borderColorError;
};

// Validating font color
const styleIsValidColor = ({ theme, disabled, isValid }) => {
  if (disabled) {
    return theme.fontColorDisabled;
  }
  return isValid ? theme.fontColorBase : theme.fontColorError;
};

// Error font color
const styleErrorFont = (theme) => ({
  color: theme.fontColorError,
});

// Error border color
const styleErrorBorder = (theme) => ({
  border: `2px solid ${theme.borderColorError}`,
});

export { styleIsValidColor, styleIsValidBorder, styleErrorFont, styleErrorBorder };
