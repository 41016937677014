import sessionStore from "./storageAPIs/sessionStore";
import { removeUndefined } from "./utils";

const RegisterHandler = (authenticator, config, analytics) => {
  let source;

  const setSource = (src) => {
    source = src;
  };

  const getUserMetadata = (registerForm, type) =>
    removeUndefined({
      origin: config.clientID,
      given_name: `${registerForm.firstName.trim()}`,
      family_name: `${registerForm.lastName.trim()}`,
      consentGiven: `${registerForm.consent}`,
      consentTimestamp: Date.now().toString(),
      type: type || sessionStore.getType(),
      source: sessionStore.getSource(),
      lang: `${sessionStore.getLang()}`,
      personal_info: JSON.stringify(
        removeUndefined({
          postal_code: registerForm.postalCode,
          birth_year: registerForm.birthYear,
          phone_number: registerForm.phoneNumber,
        })
      ),
    });

  const sendSignupSuccessEvent = () => {
    analytics.sendRegistration(true, false, null, source);
  };

  const sendSignupErrorEvent = () => {
    analytics.sendRegistration(false, false, null, source);
  };

  const handleSignupError = (signupError) => {
    const errors = Array.isArray(signupError) ? signupError : [signupError];
    sendSignupErrorEvent();
    return { errors };
  };

  const register = async (userData, type) => {
    const userMetadata = getUserMetadata(userData, type);
    const { email, password } = userData;
    try {
      await authenticator.signup(email, password, userMetadata);
      sendSignupSuccessEvent();
    } catch (signupError) {
      return handleSignupError(signupError);
    }
  };

  return { register, setSource };
};

export default RegisterHandler;
