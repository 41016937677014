import React from "react";
import styled, { keyframes } from "styled-components";

const dots = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Dot = styled.span`
  animation: ${dots} 1s infinite;
`;

const LoadingAnim = styled.div`
  font-size: 6em;
  margin: auto;
  align-self: center;
  ${Dot}:nth-child(2) {
    animation-delay: 0.2s;
  }
  ${Dot}:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const Container = styled.div`
  background-color: transparent;
  outline: none;
  border: 0px solid;
  margin-bottom: 50px;
`;

const Loading = () => (
  <Container tabIndex="0">
    <LoadingAnim>
      <Dot>.</Dot>
      <Dot>.</Dot>
      <Dot>.</Dot>
    </LoadingAnim>
  </Container>
);

export default Loading;
