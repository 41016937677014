import "../polyfills";

import EmbeddedCore from "../core/Embedded/EmbeddedCore";

const extInterface = {
  init: (props) => {
    const core = EmbeddedCore(props);

    return {
      login: core.redirectToHostedLogin,
      redirectRegister: core.redirectToHostedRegistration,
      logout: core.logout,
      formLogin: async (username, password, options) => {
        const opts = { disableCallback: false, ...options };
        return core.login(username, password, opts.disableCallback);
      },
      checkSession: core.checkSession,
      getAccessToken: core.getAccessToken,
      getIdToken: core.getIdToken,
      getRegistrationUrl: core.getRegistrationUrl,
      getVerifyEmailUrl: core.getVerifyEmailUrl,
      getUser: core.getUser,
      getUpdatedUser: core.getUpdatedUser,
      idTokenIsValid: core.idTokenIsValid,
      reset: core.reset,
      cookiesEnabled: core.cookiesEnabled,
      checkExternalSession: core.checkExternalSession,
      getLoginHandler: core.getLoginHandler,
      getLogoutHandler: core.getLogoutHandler,
    };
  },
};

export default extInterface;
