import { css } from "styled-components";

export const LinkFocusStyle = css`
  &:active,
  &:focus {
    color: ${(props) => props.theme.linkColor || "black"} !important;
    text-decoration: underline;
    border-radius: 2px;
    box-shadow: 0 0 0 3px
      ${(props) => (props.theme.highlightColor ? props.theme.highlightColor : props.theme.almaLightPurple)};
    outline: none;
  }
`;

export const ButtonFocusStyle = css`
  box-shadow: 0 0 0 1px #fff,
    0 0 0 4px ${(props) => (props.theme.highlightColor ? props.theme.highlightColor : props.theme.almaLightPurple)};
  outline: none;
`;

export const LinkButtonFocusStyle = css`
  box-shadow: inset 0 0 0 3px
    ${(props) => (props.theme.highlightColor ? props.theme.highlightColor : props.theme.almaLightPurple)};
  outline: none;
`;

export const InputFocusStyle = css`
  box-shadow: 0 0 0 3px
    ${(props) => (props.theme.highlightColor ? props.theme.highlightColor : props.theme.almaLightPurple)};
  border: 1px solid ${(props) => (props.theme.highlightColor ? "#4A4A4A" : "#a04191")};
`;

export const CheckboxFocusStyle = css`
  box-shadow: 0 0 0 1px #fff,
    0 0 0 4px ${(props) => (props.theme.highlightColor ? props.theme.highlightColor : props.theme.almaLightPurple)};
`;

export const CloseButtonFocusStyle = css`
  &:active,
  &:focus {
    box-shadow: 0 0 0 3px
      ${(props) => (props.theme.highlightColor ? props.theme.highlightColor : props.theme.almaLightPurple)};
    outline: none;
  }
`;
