import { authenticationEvent, loginFailure, logout, passwordReset, registration, someFailure } from "./eventNames";
import providers from "./providers";

const hasGAInstance = () => typeof ga !== "undefined";

const dispatchJSEvent = (JSEvent, action, label) => {
  const event = document.createEvent("Event");
  event.initEvent(JSEvent, true, true);
  event.detail = { eventCategory: "almaTunnus", eventAction: action, eventLabel: label };
  window.dispatchEvent(event);
};

const getLocation = (originUrl) => {
  if (originUrl) return originUrl;
  const [path, params] = window.location.href.split(/#access_token|\?/);
  if (typeof params === "undefined") return path;
  // Drop all utm params
  const pars = params.split(/[?|&]/g).filter((p) => p.lastIndexOf("utm_", 0) === -1);
  return `${path}${pars.length > 0 ? "?" : ""}${pars.join("&")}`;
};

export default (config, state, analyticsState, logger) => {
  const gaIsDisabled = () => config?.disableGa || config?.extraParams?.disable_ga === "true"; // embedded // universal

  const CallbackTrigger = (calls, callback) => {
    let count = calls;
    return {
      countDown: () => --count <= 0 && callback(),
    };
  };

  const logSendEvent = (eventAction, eventLabel, JSEvent) => {
    if (getTrackers().length > 0) {
      logger.logGATitle(`sendAnalyticsEvent: ${eventAction}`);
      logger.log(`GA: eventAction: ${eventAction}, eventLabel: ${eventLabel}, JSEvent: ${JSEvent}`);
    } else {
      logger.logGATitle(`sendAnalyticsEvent: ${eventAction}`);
      logger.log("No trackers to send to");
    }
  };

  const sourceIsValid = (source) => source && typeof source !== "undefined" && source !== "undefined";
  const trackerHasSourceSlot = (tracker) => !!tracker.state.CDSlotSource;

  const setEventSourceDimension = (tracker, source = state.source) => {
    if (sourceIsValid(source) && trackerHasSourceSlot(tracker)) tracker.setCDSlotSource(source);
  };

  const getTrackers = () => analyticsState.trackers;

  const canSend = () => (config.waitForLoad ? analyticsState.siteAnalyticsSet : true);

  const handleEventFunction = (f, trigger) => {
    const execFunction = () => (trigger ? f(trigger(getTrackers().length)) : f());
    return canSend() ? execFunction() : analyticsState.eventQueue.push(execFunction);
  };

  const getDimensionLogin = (action) => action.split(/-(.+)/)[1] || "login";

  const sendLoginFailure = (eventAction) => {
    if (gaIsDisabled() || !hasGAInstance()) return false;
    const eventLabel = `failure-${eventAction}`;
    const JSEvent = "almatunnus-login";
    const f = () => {
      logSendEvent(eventAction, eventLabel, JSEvent);
      dispatchJSEvent(JSEvent, eventAction, eventLabel);
      const location = getLocation(config.originUrl);
      getTrackers().forEach((tracker) => {
        tracker.setLoginDimension(eventAction);
        setEventSourceDimension(tracker);
        tracker.setClientIdDimension(config.clientID);
        tracker.sendEvent({ eventAction, eventLabel, location });
      });
    };
    handleEventFunction(f);
  };

  const sendAuthenticationEvent = ({ accountHash }, action, source, cb) => {
    if (gaIsDisabled() || !hasGAInstance()) return cb ? cb() && false : false;
    const JSEvent = "almatunnus-login";
    const f = (trigger) => {
      const eventLabel = `success-${action}`;
      logSendEvent(action, eventLabel, JSEvent);
      dispatchJSEvent(JSEvent, action, eventLabel);
      const location = getLocation(config.originUrl);
      getTrackers().forEach((tracker) => {
        if (tracker.custom) tracker.unsetCDSlotSource();
        tracker.setUserIdDimension(accountHash);
        tracker.setUserId(accountHash);
        tracker.setLoginDimension(getDimensionLogin(action));
        tracker.setClientIdDimension(config.clientID);
        setEventSourceDimension(tracker, source);
        const eventAction = tracker.custom ? "login" : action;
        tracker.sendEvent({ eventAction, eventLabel, location }, trigger);
      });
    };
    const trigger = cb ? (count) => CallbackTrigger(count, cb) : null;
    handleEventFunction(f, trigger);
  };

  const sendSomeFailure = (provider) => {
    if (gaIsDisabled() || !hasGAInstance()) return false;
    const f = () => {
      const eventAction = `login-${providers[provider]}`;
      const eventLabel = `failure-login-${providers[provider]}`;
      dispatchJSEvent("almatunnus-login", eventAction, eventLabel);
      logSendEvent(eventAction, eventLabel, "almatunnus-login");
      const location = getLocation(config.originUrl);
      getTrackers().forEach((tracker) => {
        if (tracker.custom) tracker.unsetCDSlotSource();
        tracker.setLoginDimension(`login-${providers[provider]}`);
        tracker.setClientIdDimension(config.clientID);
        setEventSourceDimension(tracker);
        tracker.sendEvent({ eventAction, eventLabel, location });
      });
    };
    handleEventFunction(f);
  };

  const sendLogout = (cb, source) => {
    if (gaIsDisabled() || !hasGAInstance()) return false;
    const f = (trigger) => {
      dispatchJSEvent("almatunnus-logout", "logout", "logout");
      logSendEvent("logout", "logout", "almatunnus-logout");
      const location = getLocation(config.originUrl);
      getTrackers().forEach((tracker) => {
        if (tracker.custom) tracker.unsetCDSlotSource();
        tracker.setLoginDimension("logout");
        tracker.setClientIdDimension(config.clientID);
        setEventSourceDimension(tracker, source);
        tracker.sendEvent({ eventAction: "logout", eventLabel: "logout", location }, trigger);
      });
    };
    const trigger = cb ? (count) => CallbackTrigger(count, cb) : null;
    handleEventFunction(f, trigger);
  };

  const sendRegistration = (result, deniedConsent = false, cb, source) => {
    if (gaIsDisabled() || !hasGAInstance()) return false;
    const f = (trigger) => {
      const eventLabel = `${result ? "success" : "failure"}-registration${deniedConsent ? "-deniedconsent" : ""}`;
      dispatchJSEvent("almatunnus-signup", "registration", eventLabel);
      logSendEvent("registration", eventLabel, "almatunnus-signup");
      const location = getLocation(config.originUrl);
      getTrackers().forEach((tracker) => {
        if (tracker.custom) tracker.unsetCDSlotSource();
        tracker.setRegisterDimension(result ? "true" : "false");
        tracker.setClientIdDimension(config.clientID);
        setEventSourceDimension(tracker, source);
        tracker.sendEvent({ eventAction: "registration", eventLabel, location }, trigger);
      });
    };
    const trigger = cb ? (count) => CallbackTrigger(count, cb) : null;
    handleEventFunction(f, trigger);
  };

  const sendPasswdReset = (result) => {
    if (gaIsDisabled() || !hasGAInstance()) return false;
    const f = () => {
      const eventAction = "passwd-reset";
      const resolveResetEvent = (result) => {
        if (typeof result === "undefined") return [undefined, "almatunnus-passwd-reset-start"];
        if (result) return ["success-passwd-rest", "almatunnus-passwd-reset-success"];
        return ["failure-passwd-reset", "almatunnus-passwd-reset-failure"];
      };
      const [eventLabel, JSEvent] = resolveResetEvent(result);
      dispatchJSEvent(JSEvent, eventAction, eventLabel);
      logSendEvent(eventAction, eventLabel, JSEvent);
      const location = getLocation(config.originUrl);
      getTrackers().forEach((tracker) => {
        if (tracker.custom) tracker.unsetCDSlotSource();
        tracker.setForgotPwdDimension("true");
        tracker.setClientIdDimension(config.clientID);
        setEventSourceDimension(tracker);
        tracker.sendEvent({ eventAction, eventLabel, location });
      });
    };
    handleEventFunction(f);
  };

  const getSendEvent = (sendEvent) => {
    switch (sendEvent) {
      case loginFailure:
        return sendLoginFailure;
      case authenticationEvent:
        return sendAuthenticationEvent;
      case someFailure:
        return sendSomeFailure;
      case logout:
        return sendLogout;
      case registration:
        return sendRegistration;
      case passwordReset:
        return sendPasswdReset;
    }
  };
  return {
    runBufferedEvents: (bufferedEvents) =>
      bufferedEvents.forEach(({ e, args }) => {
        getSendEvent(e).apply(this, args);
      }),
    sendLoginFailure,
    sendAuthenticationEvent,
    sendSomeFailure,
    sendLogout,
    sendRegistration,
    sendPasswdReset,
  };
};
