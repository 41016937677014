import React from "react";
import styled from "styled-components";

const VectorDown = () => (
  <Icon width="13" height="8" viewBox="0 0 13 8" fill="none">
    <path d="M1 1C3.04286 3.04286 5.51786 5.51786 6.5 6.5L12 1" />
    <path d="M1 1C3.04286 3.04286 5.51786 5.51786 6.5 6.5L12 1" />
  </Icon>
);

export default VectorDown;

const Icon = styled.svg`
  stroke: ${(props) => props.theme.linkColor};
`;
