import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { useModalStageContext } from "../providers/modal/ModalStageContext";
import { useUIContext } from "../providers/UIContextProvider";
import { isUsingWebflow } from "../utils/helper-functions";
import { CloseButtonFocusStyle } from "./Focus";

const CloseButton = ({ title }) => {
  const { closeModalOnClick, isUniversal } = useModalStageContext();
  const { t } = useUIContext();
  const buttonVisible = t("settings.closeButtonVisible", true);
  const enabled = buttonVisible || !isUniversal;
  const webflow = isUsingWebflow();
  return !webflow && enabled ? (
    <ClosingButton
      type="button"
      title={title}
      className="alma-tunnus-close"
      tabIndex="0"
      aria-label={title}
      id="alma-tunnus-button-close"
      onClick={closeModalOnClick}
      webflow={webflow}
    >
      <svg viewBox="0 0 18 18">
        <line x1="0" y1="18" x2="18" y2="0" stroke="black" strokeWidth="1" shapeRendering="geometricPrecision" />
        <line x1="0" y1="0" x2="18" y2="18" stroke="black" strokeWidth="1" shapeRendering="geometricPrecision" />
      </svg>
    </ClosingButton>
  ) : null;
};

CloseButton.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CloseButton;

const ClosingButton = styled.button`
  display: ${(props) => (props.webflow ? "none" : "block")};
  padding: 8px;
  box-sizing: border-box;
  border-radius: 2px;
  border: none;
  width: 34px;
  height: 34px;
  background-color: transparent;
  position: absolute;
  top: 50%;
  right: -8px;
  transform: translateY(-50%);

  &:hover {
    background-color: #f6f6f6;
    cursor: pointer;
  }

  ${CloseButtonFocusStyle}
`;
