import PropTypes from "prop-types";
import React, { forwardRef, useState } from "react";
import styled from "styled-components";

import useInputFocus from "../../hooks/useInputFocus";
import { useUIContext } from "../../providers/UIContextProvider";
import theme from "../../theme/theme.settings";
import { TextSmall } from "../Text";
import { FieldContainer, InputContainer, InputField, InputLabel } from "./InputComponents";
import { ButtonFocusStyle } from "../Focus";

const InputPassword = forwardRef((props, ref) => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [allowPwdToggle, setAllowPwdToggle] = useState(false);

  const changeVisiblePasswordState = () => {
    setVisiblePassword(!visiblePassword);
    ref.current.focus();
  };
  const { t } = useUIContext();

  const { label, id, isError, isValid, disabled, value } = props;
  const [focused, onInputBlur, onInputFocus] = useInputFocus();

  // eslint-disable-next-line no-unused-vars
  const has1PasswordAutofill = () =>
    value &&
    ref.current.dataset &&
    typeof ref.current.dataset["com.agilebits.onepassword.initialValue"] !== "undefined";

  // check lastpassword autofill
  // LastPass sets base64 data as background-image for the lock icon inside input
  const hasLastPassAutofill = () =>
    ref.current &&
    ref.current.style &&
    ref.current.style.backgroundImage &&
    ref.current.style.backgroundImage.indexOf("url(") > -1 &&
    ref.current.autocomplete === "off";
  //  && !hasWebkitAutofill()
  const pwdLengthOverThreshold = () =>
    (value && value.length >= 1) || (ref && ref.current && ref.current.value.length >= 1);
  const pwdToggleAllowed = () => pwdLengthOverThreshold() && !has1PasswordAutofill() && !hasLastPassAutofill();
  const handleChange = () => {
    setAllowPwdToggle(pwdToggleAllowed());
    if (props.onChange) props.onChange();
  };

  return (
    <InputContainer>
      {label && (
        <InputLabel htmlFor={id} isValid={isValid} disabled={disabled}>
          {label}
        </InputLabel>
      )}
      <FieldContainer
        // Bind onBlur and onFocus functions from props here,
        // so that toggling password visibility won't trigger
        // validation errors
        onBlur={(e) => {
          if (typeof props.onBlur === "function") props.onBlur(e);
        }}
        onFocus={(e) => {
          if (typeof props.onFocus === "function") props.onFocus(e);
        }}
      >
        <PasswordInputField
          {...props}
          isError={isError}
          type={visiblePassword ? "text" : "password"}
          ref={ref}
          onChange={handleChange}
          // Internal focus handlers, will take care
          // of the styling
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          focused={focused}
          spellcheck="false"
        />
        {allowPwdToggle && (
          <ShowPasswordToggler
            type="button"
            className="darken"
            onClick={changeVisiblePasswordState}
            aria-label={visiblePassword ? t("passwordInput.hidePassword") : t("passwordInput.showPassword")}
          >
            <span>{visiblePassword ? t("passwordInput.hide") : t("passwordInput.show")}</span>
          </ShowPasswordToggler>
        )}
      </FieldContainer>
    </InputContainer>
  );
});

InputPassword.defaultProps = {
  disabled: false,
  label: null,
  isValid: true,
  isError: false,
  onKeyPress: null,
  value: undefined,
  onChange: null,
  onBlur: null,
  onFocus: null,
  onInput: null,
};

InputPassword.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  label: PropTypes.string,
  isValid: PropTypes.bool,
  isError: PropTypes.bool,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onInput: PropTypes.func,
};

export default InputPassword;

// STYLED COMPONENT
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

const PasswordInputField = styled(InputField)`
  padding-right: 65px;
`;

const ShowPasswordToggler = styled.button`
  ${TextSmall}
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-decoration: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 14px 10px 8px;
  margin: 0 4px 0 -5px;
  user-select: none;
  color: ${theme.mediumGray};
  cursor: pointer;

  &:active,
  &:focus {
    outline: 0;
    span {
      ${ButtonFocusStyle}
      border-radius: 2px;
      padding: 2px;
      margin: -2px;
    }
  }
`;
