import styled, { css } from "styled-components";

import { generateFontFamily } from "../utils/font-manager";

const header = css`
  font-family: ${generateFontFamily("GalanoGrotesqueAlt-Bold")};
  color: ${({ color }) => color || "black"};
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
`;

export const H1 = styled.h2`
  ${header};
  font-size: 22px;
  margin-top: 5px;
  letter-spacing: -0.5px;
`;

export const H2 = styled.h2`
  ${header};
  font-size: 18px;
  margin-top: 0;
  letter-spacing: -0.3px;
`;
