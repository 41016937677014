import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";

import useInputFocus from "../hooks/useInputFocus";
import { isBlack } from "../utils/helper-functions";
import Spinner from "./animated/Spinner";
import { ButtonFocusStyle, LinkButtonFocusStyle } from "./Focus";
import { TextPrimaryButton, TextRegular, TextSmall } from "./Text";

const LoadingContentContainer = styled.span`
  position: relative;
  display: block;
`;
const SpinnerContainer = styled.span`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  align-items: center;
`;

const TransparentContent = styled.span`
  opacity: 0;
`;

const hidden = css`
  visibility: hidden;
  outline: none;
  focus: none;
`;

const ButtonStructure = css`
  box-sizing: border-box;
  display: block;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  height: 50px;
  width: auto;
  min-width: 180px;
  padding: 0 45px;
  margin: 30px auto 0 auto;
`;

const activeStyledButton = css`
  ${ButtonFocusStyle}
`;

export const StyledButton = styled.button`
  ${({ isHidden }) => isHidden && hidden};
  ${TextPrimaryButton}
  ${ButtonStructure}
  ${({ isActive }) => {
    if (isActive) return activeStyledButton;
    return null;
  }}
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  background-color: ${(props) => props.theme.buttonColor};
  background-position: center;
  color: white;
  &:active,
  &:focus {
    ${activeStyledButton}
  }
  &:hover {
    box-shadow: inset 0 0 0 30px ${(props) =>
      props.theme.buttonColor && isBlack(props.theme.buttonColor)
        ? "rgba(255, 255, 255, 0.17)"
        : "rgba(0, 0, 0, 0.17)"};
  }
`;

const activeLinkButtonStyle = css`
  ${LinkButtonFocusStyle}
`;

export const LinkButtonStyle = styled.button`
  ${({ isActive }) => {
    if (isActive) return activeLinkButtonStyle;
    return null;
  }}
  ${ButtonStructure};
  ${TextRegular}
  border-radius: 3px;
  background: transparent;
  color: ${(props) => props.theme.linkColor};
  margin-top: 0;
  margin-bottom: 0;
  height: auto;
  min-height: 35px;
  padding: 6px 5px;
  text-decoration: underline;
  svg {
    margin-right: 6px;
  }
  &:active,
  &:focus {
    ${activeLinkButtonStyle}
  }

  @media (min-width: ${(props) => props.theme.breakpoints.mobileM}) {
    padding: 6px 15px;
  }
`;

export const LinkButtonSmallStyle = styled(LinkButtonStyle)`
  ${TextSmall}
  color: ${(props) => props.theme.linkColor};
`;

export const LinkButtonFluidStyle = styled(LinkButtonStyle)`
  min-height: 0;
  padding: 6px;
  margin: -6px;
`;

const Button = React.forwardRef((props, ref) => {
  const { children, onClick, performingAction, ButtonStyle, ...rest } = props;

  const preventDoubleClick = useRef();
  const [isActive, onActiveEnd, onActiveStart] = useInputFocus();

  const handleClick = (e) => !preventDoubleClick.current() && onClick(e);

  useEffect(() => {
    let timeout;
    preventDoubleClick.current = () => {
      if (timeout) return true;
      timeout = setTimeout(() => {
        timeout = null;
      }, 800);
      return false;
    };
    return () => clearTimeout(timeout);
  }, []);

  return (
    <ButtonStyle
      onClick={handleClick}
      onPointerDown={onActiveStart}
      onPointerUp={onActiveEnd}
      onPointerLeave={onActiveEnd}
      onFocus={onActiveStart}
      onBlur={onActiveEnd}
      isActive={isActive}
      ref={ref}
      {...rest}
    >
      {performingAction ? (
        <LoadingContentContainer>
          <TransparentContent>{children}</TransparentContent>
          <SpinnerContainer>
            <Spinner color="white" />
          </SpinnerContainer>
        </LoadingContentContainer>
      ) : (
        children
      )}
    </ButtonStyle>
  );
});

Button.defaultProps = {
  children: null,
  onClick: () => {},
  performingAction: false,
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  performingAction: PropTypes.bool,
  ButtonStyle: PropTypes.object.isRequired,
};

export default Button;
export const PrimaryButton = React.forwardRef((props, ref) => (
  <Button ref={ref} tabIndex="0" ButtonStyle={StyledButton} {...props} />
));

const WithLinkColor = (LinkB) => {
  return <LinkB />;
};
export const LinkButton = (props) =>
  WithLinkColor((wProps) => <Button tabIndex="0" ButtonStyle={LinkButtonStyle} {...wProps} {...props} />);
export const LinkButtonSmall = (props) =>
  WithLinkColor((wProps) => <Button tabIndex="0" ButtonStyle={LinkButtonSmallStyle} {...wProps} {...props} />);
export const LinkButtonFluid = (props) =>
  WithLinkColor((wProps) => <Button tabIndex="0" ButtonStyle={LinkButtonFluidStyle} {...wProps} {...props} />);
