import "../polyfills";

import React from "react";
import ReactDOM from "react-dom";

import Tunnus from "../view/Tunnus";

const TunnusReactLib = (configProps, elementToAttach) => {
  ReactDOM.render(
    <div>
      <Tunnus {...configProps} />
    </div>,
    document.getElementById(elementToAttach)
  );
};

export default TunnusReactLib;
