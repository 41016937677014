import styled from "styled-components";

import theme from "../theme/theme.settings";
import { generateFontFamily } from "../utils/font-manager";

export const ContentContainer = styled.div`
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

  @media (min-width: ${theme.breakpoints.mobileS}) {
    padding: 0 20px;
  }

  @media (min-width: ${theme.breakpoints.mobileM}) {
    padding: 0 25px;
  }

  @media (min-width: ${theme.breakpoints.mobileL}) {
    padding: 0 30px;
  }
`;

export const MainContainer = styled.div`
  min-width: 275px;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  background-color: white;
  position: relative;
  min-height: 100vh;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${theme.breakpoints.mobileL}) {
    left: 50%;
    top: 15px;
    transform: translate(-50%, 0);
    width: 420px;
    min-height: auto;
    border-radius: 2px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    margin-bottom: 15px;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : "")};
  background-color: ${({ backgroundImage }) => (backgroundImage ? `rgba(0, 0, 0, 0);` : `rgba(0, 0, 0, 0.6);`)};
  @media (min-width: ${theme.breakpoints.mobileL}) {
    background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : "")};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  font-family: ${generateFontFamily("GalanoGrotesqueAlt-Regular")};
  color: #4a4a4a;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
`;
