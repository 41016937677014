import React from "react";
import styled from "styled-components";

const LinkedIn = () => (
  <Icon
    clip-rule="evenodd"
    fill-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
    viewBox="0 0 62 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m60 4.5c0-2.485-2.015-4.5-4.5-4.5h-55.5v60h55.5c2.485 0 4.5-2.015 4.5-4.5z" fill="#0073b1" />
    <path
      d="m59.468 0c1.246 1.099 2.032 2.708 2.032 4.5v51c0 1.792-.786 3.401-2.032 4.5h-3.968c2.485 0 4.5-2.015 4.5-4.5v-51c0-2.485-2.015-4.5-4.5-4.5h-.75z"
      fill="#075b8c"
    />
    <g fill="#fff">
      <path d="m9.099 22.922h8.776v28.311h-8.776z" />
      <path d="m51 51.233h-8.776v-13.872c0-3.397 0-7.644-4.53-7.644s-5.379 3.68-5.379 7.361v14.155h-8.777v-28.311h8.493v3.964c1.699-2.831 4.813-4.813 8.211-4.53 9.059 0 10.475 5.945 10.475 13.589z" />
      <path d="m13.346 18.959c-2.831 0-5.096-2.265-5.096-5.096 0-2.832 2.265-5.096 5.096-5.096s5.096 2.264 5.096 5.096c0 2.831-2.265 5.096-5.096 5.096z" />
    </g>
  </Icon>
);

export default LinkedIn;

const Icon = styled.svg``;
