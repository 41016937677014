export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function updateQueryStringParameter(uri, key, value) {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i");
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";
  return uri.match(re) ? uri.replace(re, `$1${key}=${value}$2`) : `${uri}${separator}${key}=${value}`;
}

export const getCMPDataFromUrl = () => (typeof window === "undefined" ? null : getParameterByName("cmp"));

export const getSourceFromUrl = () => (typeof window === "undefined" ? null : getParameterByName("source"));

// remove undefined values from the object
export const removeUndefined = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (
      obj[key] !== undefined &&
      obj[key] !== null &&
      obj[key] !== "" &&
      obj[key] !== "{}" &&
      obj[key] !== "null" &&
      obj[key] !== "undefined"
    ) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};
