import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { PrimaryButton } from "../components/Buttons";
import { Container } from "../components/Containers";
import { H1 } from "../components/Headers";
import IconDone from "../components/icons/Done";
import P from "../components/P";
import { useUIContext } from "../providers/UIContextProvider";
import NotificationFrame from "./NotificationFrame";

const VerifyEmailNotification = ({ closeNotification }) => {
  const { t } = useUIContext();
  return (
    <Container role="alert">
      <IconDone />
      <H1custom>{t("notifications.resendVerificationSuccess.heading")}</H1custom>
      <P dangerouslySetInnerHTML={{ __html: t("notifications.resendVerificationSuccess.description") }} />
      <P dangerouslySetInnerHTML={{ __html: t("notifications.resendVerificationSuccess.additionalInfo") }} />
      <PrimaryButtonCustom type="button" onClick={closeNotification}>
        {t("common.labelClose")}
      </PrimaryButtonCustom>
    </Container>
  );
};

VerifyEmailNotification.propTypes = {
  closeNotification: PropTypes.func.isRequired,
};

const H1custom = styled(H1)`
  margin: 20px auto 15px auto;
`;

const PrimaryButtonCustom = styled(PrimaryButton)`
  margin-top: 20px;
`;

export default NotificationFrame(VerifyEmailNotification);
