import buffersEvents from "./buffersEvents";
import loadsAnalytics from "./loadsAnalytics";
import sendsEvents from "./sendsEvents";

const Analytics = (config, state, logger, eventsBuffer) => {
  const analyticsState = {
    siteAnalyticsSet: false,
    trackers: [],
    eventQueue: [],
  };
  const analyticsLoader = loadsAnalytics(config, state, analyticsState, logger);
  const eventsSender = sendsEvents(config, state, analyticsState, logger);
  if (eventsBuffer) eventsSender.runBufferedEvents(eventsBuffer);
  return {
    isLoaded: () => analyticsState.siteAnalyticsSet,
    ...analyticsLoader,
    ...eventsSender,
  };
};

export const AnalyticsBuilder = (config, state, logger) => {
  let analytics = config.analytics;
  const eventsBuffer = buffersEvents();

  const build = () => Analytics({ ...config, analytics }, state, logger, eventsBuffer.getEventsBuffer());

  const initSiteAnalytics = (siteAnalytics) => {
    analytics = siteAnalytics;
  };

  return { build, initSiteAnalytics, ...eventsBuffer, isLoaded: () => false };
};

export default Analytics;
