import sessionStore from "../../storageAPIs/sessionStore";

export default (config, funcs) => {
  const state = {
    checkUrl: config.thirdPartyCookieCheckUrl || `https://assets.${config.domain}/cookies/cookieCheckStart.html`,
    stateIsRunning: false,
  };

  const removeIframe = () => {
    const frame = document.getElementsByName("tunnus-iframe")[0];
    if (frame) {
      funcs.logger.log("removing iframe");
      frame.parentNode.removeChild(frame);
    }
  };

  const iframeEventListener = (resolve) => (evt) => {
    if (evt.data === "at-cookies-false" || evt.data === "at-cookies-true") {
      const cookies = evt.data === "at-cookies-true";
      funcs.logger.log(`3rdp cookies: at-cookies-${cookies}`);
      sessionStore.setIsUniversalPageFlow(cookies);
      state.checkIsRunning = false;
      resolve(cookies);
      removeIframe();
    }
  };

  return {
    runCookieCheck: () => {
      if (state.checkIsRunning || typeof document === "undefined") return false;
      state.checkIsRunning = true;
      const iframe = document.createElement("iframe");
      iframe.setAttribute("name", "tunnus-iframe");
      iframe.setAttribute("style", "display: none");
      iframe.setAttribute("src", `${state.checkUrl}?t=${new Date().getTime()}`);
      document.body.appendChild(iframe);

      return new Promise((resolve) => {
        window.addEventListener("message", iframeEventListener(resolve), false);
      });
    },
  };
};
