import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { LinkFocusStyle } from "./Focus";
import { TextRegular } from "./Text";

// eslint-disable-next-line react/prefer-stateless-function
const A = (props) => {
  const { children, ...rest } = props;
  return (
    <ALink {...rest} target="_blank" rel="noopener noreferrer">
      {children}
    </ALink>
  );
};

A.defaultProps = {
  style: null,
  children: null,
};

A.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node, PropTypes.object]),
  style: PropTypes.object,
};

export default A;

// STYLED COMPONENT
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

const ALink = styled.a`
  ${TextRegular}
  color: ${(props) => props.theme.linkColor || "black"};
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
  
  ${LinkFocusStyle}
  &:hover {
    color: ${(props) => props.theme.linkColor || "black"};
    text-decoration: underline;
  }
`;
