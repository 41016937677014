export const isValidAsset = (asset) => !!asset || asset === "" || asset === false;

export const findNestedAsset = (assets, keys) => {
  let next,
    curr = assets;

  for (const nextKey of keys) {
    next = curr[nextKey];
    if (isValidAsset(next)) curr = next;
    else return null;
  }

  return curr;
};

export default (assets, forceKeys, core) => {
  return forceKeys
    ? (key) => key
    : (key, retNull, src) => {
        const source = src || core?.getSource();
        const keys = key.split(".");
        const translation = findNestedAsset(assets, keys);
        if (source) {
          keys[keys.length - 2] = `${keys[keys.length - 2]}.${source}`;
          const altTranslation = findNestedAsset(assets, keys);

          if (altTranslation !== null) return altTranslation;
        }
        if (translation) return translation;
        return translation === "" || retNull ? null : `[${key}]`;
      };
};
