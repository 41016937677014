import React from "react";

import { useUIContext } from "../../providers/UIContextProvider";
import EmailSendingForm from "./EmailSendingForm";

export default () => {
  const { core } = useUIContext();
  const sendPwdReset = (email) => core.sendPwdReset(email);
  return <EmailSendingForm sendAction={sendPwdReset} nextModal="RESETSENT" assetScope="passwordReset" />;
};
