const dimensionName = "dimension";
const defaultDimensions = {
  CDSlotType: `${dimensionName}4`,
  CDSlotSource: `${dimensionName}23`,
  CDSlotClientID: null,
  REGISTER: `${dimensionName}5`,
  SENT_PWD_RESET: `${dimensionName}7`,
  USER_ID: `${dimensionName}9`,
};

const createFunctionWithTimeout = (callback = () => {}, opt_timeout = 1000) => {
  let called = false;
  const fn = () => {
    if (called) return;
    called = true;
    callback();
  };
  setTimeout(fn, opt_timeout);
  return fn;
};

const usesAnalytics = (state) => {
  const setDimension = (dimensionValue, dimensionSlot) => ga(`${state.name}.set`, dimensionSlot, dimensionValue);
  return {
    setDisabled: (disabled) => {
      window[`ga-disable-${state.account}`] = disabled;
    },
    setLoginDimension: (eventAction) => state?.CDSlotType && setDimension(eventAction, state.CDSlotType),
    setUserIdDimension: (accountHash) => state?.USER_ID && setDimension(accountHash, state.USER_ID),
    setRegisterDimension: (result) => state?.REGISTER && setDimension(result, state.REGISTER),
    setForgotPwdDimension: (forgot) => state?.SENT_PWD_RESET && setDimension(forgot, state.SENT_PWD_RESET),
    setClientIdDimension: (id) => state?.CDSlotClientID && setDimension(id, state.CDSlotClientID),
    setUserId: (userId) => ga(`${state.name}.set`, "userId", userId),
    setCDSlotSource: (source) => state.CDSlotSource && ga(`${state.name}.set`, state.CDSlotSource, source),
    unsetCDSlotSource: () => state.CDSlotSource && ga(`${state.name}.set`, state.CDSlotSource, null),
  };
};

const gaSend = (state, payload) => ga(`${state.name}.send`, "event", payload);

const sendsEvent = (state) => ({
  sendEvent: ({ eventAction, eventLabel, location }, trigger) =>
    gaSend(state, {
      eventCategory: "almaTunnus",
      eventAction,
      eventLabel,
      nonInteraction: state.nonInteraction,
      location,
      page: location,
      hitCallback: createFunctionWithTimeout(trigger?.countDown, 1000),
    }),
});

const setAnonymizeIp = (trackerName) => ga(`${trackerName}.set`, "anonymizeIp", true);

// name, account, nonInteraction, dimensions ( CDSlotSource, CDSlotType, CDSlotClientID )
export const CustomTracker = (config) => {
  ga("create", config.account, { cookieDomain: "auto", name: config.name });
  setAnonymizeIp(config.name);
  const state = config;
  return Object.assign({ state, custom: true }, usesAnalytics(state), sendsEvent(state));
};

export default (name, account, nonInteraction) => {
  ga("create", account, { cookieDomain: "auto", name });
  setAnonymizeIp(name);
  const state = {
    name,
    account,
    nonInteraction,
    ...defaultDimensions,
  };

  return Object.assign({ state }, usesAnalytics(state), sendsEvent(state));
};
