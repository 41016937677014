import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { LinkFocusStyle } from "./Focus";
import ErrorIcon from "./icons/Error";
import { TextRegular, TextSemiBold } from "./Text";

export const Container = styled.div`
  ${TextSemiBold(TextRegular)}
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 -15px 0;
  text-align: center;
  a {
    ${TextRegular}
    color: ${(props) => props.theme.linkColor || "black"};
    text-decoration: underline;
    cursor: pointer;
    ${LinkFocusStyle}
    &:hover {
      color: ${(props) => props.theme.linkColor || "black"};
      text-decoration: underline;
    }
  }
`;

const IconContainer = styled.div`
  text-align: center;
  margin-bottom: 5px;
  svg {
    display: block;
    height: 18px;
    width: 18px;
  }
`;

const AlertMessage = ({ children }) => (
  <Container>
    <IconContainer>
      <ErrorIcon />
    </IconContainer>
    <>{children}</>
  </Container>
);

AlertMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AlertMessage;

const GContainer = styled(Container)`
  margin: 20px 0;
`;

export const NoGuaranteeAlert = ({ children }) => (
  <GContainer>
    <IconContainer>
      <ErrorIcon />
    </IconContainer>
    <>{children}</>
  </GContainer>
);

NoGuaranteeAlert.propTypes = {
  children: PropTypes.node.isRequired,
};
