import React, { useState } from "react";
import styled from "styled-components";

import { LinkButtonSmall, LinkButtonSmallStyle } from "../../components/Buttons";
import { LinkFocusStyle } from "../../components/Focus";
import Letter from "../../components/icons/Letter";
import IconVector from "../../components/icons/VectorDown";
import { TextTiny } from "../../components/Text";
import { useModalStageContext } from "../../providers/modal/ModalStageContext";
import { useUIContext } from "../../providers/UIContextProvider";
import { loadFont } from "../../utils/font-manager";
import getButtonProviderConfig from "./buttonProviderConfigs";
import AltLoginButton from "./SomeButton";

const AltLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${({ hasProvidersVisible }) => (hasProvidersVisible ? "margin-top: 25px;" : "margin-top: 0;")}
  & button:not(:first-child) {
    margin-top: 20px;
  }
  ${LinkButtonSmallStyle} {
    text-decoration: none;
    ${({ hasProvidersVisible }) => (hasProvidersVisible ? "" : "margin-top: 8px;")}
  }
`;

const SomeLoginTerms = styled.div`
  ${TextTiny}
  color: ${(props) => props.theme.mediumGray};
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  > a {
    border-radius: 1px;
    color: ${(props) => props.theme.linkColor || "black"};
    text-decoration: underline;
    ${LinkFocusStyle}
    &:hover {
      color: ${(props) => props.theme.linkColor || "black"};
      text-decoration: underline;
    }
  }
`;

const MoreButton = styled(LinkButtonSmall)`
  span {
    white-space: nowrap;
  }
`;

export default () => {
  const { core, t } = useUIContext();
  const { setModalStage } = useModalStageContext();
  const [showMoreMethods, setShowMoreMethods] = useState(false);

  // Get list of list of all active identity providers.
  // From all providers filter out ones that do not have an existing configuration
  // and which have active = false
  const identityProviders = t("settings.identityProviders", true);
  const hasB2BLogin = identityProviders["azure-ad"]?.active;
  let providers =
    identityProviders &&
    Object.keys(identityProviders)
      // .filter((p) => p !== "azure-ad")
      .filter((p) => (typeof p !== "object" && getButtonProviderConfig(p)) || t(`settings.enterprise.${p}`, true))
      .filter((p) => {
        const pconf = t(`settings.identityProviders.${p}`) || t(`settings.enterprise.${p}`);
        return pconf?.active;
      });
  // Get number of initially visible identity providers, set to 0 if there is a bad value
  let visible = t("settings.identityProviders.visible", true);
  visible = visible && visible >= 0 ? visible : 0;

  const onMoreMethodsClick = () => setShowMoreMethods(true);
  const onPwdlessClick = () => setModalStage("PWDLESS");
  if (providers && providers.length > 0) {
    if (identityProviders["google-oauth2"]?.active) {
      const text = `&text=${t("settings.identityProviders.google-oauth2.loginText", true) || "Google"}`;
      const roboto = `https://fonts.googleapis.com/css?family=Roboto&display=swap${text}`;
      loadFont(roboto);
    }
    if (hasB2BLogin) {
      const B2BLogins = t("settings.enterprise");
      providers = [...providers, ...Object.keys(B2BLogins)];
    }
    const shouldDisplayShowMoreButton = !showMoreMethods && providers.length > visible;
    const visibleProviders = showMoreMethods || visible === providers.length ? providers : providers.slice(0, visible);
    const hasProvidersVisible = visibleProviders.length > 0;

    const CMPData = core.getCMPData();
    const B2BCMPData = core.getB2BCMPData();
    const getConsentMessage = (provider) => {
      if (provider === "facebook") {
        return t("errors.CMP");
      }
      return t(`errors.CMP_${provider}`, true);
    };
    return (
      <AltLoginContainer hasProvidersVisible={hasProvidersVisible} role="dialog" aria-describedby="altDescription">
        {visibleProviders.map((provider, index) => {
          if (provider === "azure-ad") {
            //this is empty
            return;
          }
          const enterpriseConnection = t(`settings.enterprise.${provider}`, true);
          if (enterpriseConnection) {
            provider = "azure-ad";
          }
          let description = t(`settings.identityProviders.${provider}.loginDescription`);
          let text = t(`settings.identityProviders.${provider}.loginText`);
          let conf = getButtonProviderConfig(provider);
          let providerName = provider === "google-oauth2" ? "google" : provider;
          const consentMissing = enterpriseConnection ? !B2BCMPData : !CMPData[providerName];
          const consentMessage = consentMissing ? getConsentMessage(provider) : null;

          if (!enterpriseConnection && provider === "azure-ad") {
            return null;
          }
          if (!conf) {
            return null;
          }
          const styles = conf.styles || null;
          const icon = conf.icon || Letter;
          return (
            <AltLoginButton
              className={`alma-tunnus-social-media-login-${provider}`}
              key={index}
              onClick={
                conf.pwdless
                  ? onPwdlessClick
                  : () =>
                      core.someLogin(
                        enterpriseConnection ? enterpriseConnection : provider,
                        enterpriseConnection ? "Almatunnus-Business" : "Almatunnus"
                      )
              }
              title={description}
              consentMessage={consentMessage}
              providerConf={styles}
              icon={icon}
              loginText={text}
            />
          );
        })}
        {shouldDisplayShowMoreButton && (
          <MoreButton onClick={onMoreMethodsClick}>
            <span>
              <IconVector />
              {t("pages.login.labelShowMoreLogins")}
            </span>
          </MoreButton>
        )}
        {hasProvidersVisible && (
          <SomeLoginTerms id="altDescription" dangerouslySetInnerHTML={{ __html: t("pages.login.someLoginTerms") }} />
        )}
      </AltLoginContainer>
    );
  }
  return null;
};
