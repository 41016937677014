import React from "react";
import styled from "styled-components";

import { TextSemiBoldInheritSize } from "../components/Text";

const BoldSpan = styled.span`
  ${TextSemiBoldInheritSize}
`;

const bold = (child, i) => (
  <BoldSpan key={i} style={{ whiteSpace: "noWrap" }}>
    {child}
  </BoldSpan>
);

const registerLink = (text, i, fs) => (
  <a
    target="_blank"
    key={i}
    href="#"
    onClick={(e) => {
      e.preventDefault();
      fs.redirectToHostedRegistration();
    }}
  >
    {bold(text)}
  </a>
);

export default {
  NAME: (user) => user.name,
  EMAIL: (user) => user.email,
  REGISTERLINK: (text, i, fs) => registerLink(text, i, fs),
};
