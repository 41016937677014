import PropTypes from "prop-types";
import React from "react";
import styled, { keyframes } from "styled-components";

const Spinner = ({ size, border, color }) => <StyledSpinner size={size} border={border} color={color} />;

export default Spinner;

Spinner.defaultProps = {
  size: "1.5em",
  border: "3px",
  color: null,
};
Spinner.propTypes = {
  size: PropTypes.string,
  border: PropTypes.string,
  color: PropTypes.string,
};

const spin = keyframes`
  to {transform: rotate(360deg);}
`;

const StyledSpinner = styled.span`
  animation: ${spin} 0.8s linear infinite;
  display: inline-block;
  box-sizing: border-box;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  padding: 0;
  margin: 0;
  border-radius: 50%;
  border: ${({ border }) => border} solid ${(props) => props.color || props.theme.buttonColor || "#FFF"};
  border-top-color: transparent;
`;
