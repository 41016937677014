import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import styled, { css } from "styled-components";

import useInputFocus from "../hooks/useInputFocus";
import { CheckboxFocusStyle } from "./Focus";
import Checkmark from "./icons/Checkmark";

const boxSize = css`
  height: 28px;
  width: 28px;
  min-height: 28px;
  min-width: 28px;
`;

const checkedStyle = css`
  border: 1px solid #93b91f;
  background-color: #93b91f;
`;

const errorStyle = css`
  border: 1px solid #ff0000;
`;

const focusedStyle = css`
  ${CheckboxFocusStyle}
  border: 1px solid #666666;
`;

const regularStyle = css`
  border: 1px solid #666666;
`;

const StyledCheckbox = styled.div`
  ${boxSize}
  ${({ isError, checked }) => {
    if (checked) return checkedStyle;
    if (isError) return errorStyle;
    return regularStyle;
  }};
  ${({ focused }) => focused && focusedStyle}
  &:hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 2px;
  transition: all 50ms;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  ${boxSize}
  margin: 0;
  position: absolute;
  opacity: 0;
  &:hover {
    cursor: pointer;
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Checkbox = forwardRef(({ checked, isError, ...rest }, ref) => {
  const [focused, onInputBlur, onInputFocus] = useInputFocus();
  return (
    <CheckboxContainer>
      <HiddenCheckbox {...rest} tabIndex="0" onFocus={onInputFocus} onBlur={onInputBlur} ref={ref} />
      <StyledCheckbox tabIndex="-1" checked={checked} isError={isError} onClick={rest.onChange} focused={focused}>
        {checked && <Checkmark fillColor="white" />}
      </StyledCheckbox>
    </CheckboxContainer>
  );
});

Checkbox.defaultProps = {
  checked: false,
  isError: false,
};
Checkbox.propTypes = {
  checked: PropTypes.bool,
  isError: PropTypes.bool,
};

export default Checkbox;
