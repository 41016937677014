import fetch from "unfetch";
import sessionStore from "../storageAPIs/sessionStore";

const EmailFunctions = (config, state, userStore) => {
  const objectToUrlString = (obj) => {
    return Object.keys(obj)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
      .join("&");
  };

  const getUrlWithModal = (modal) =>
    `https://${config.domain}/login?${objectToUrlString({
      client: config.clientID,
      realm: config.realm,
    })}&m=${modal}`;

  return {
    sendVerificationEmail: (userID) => {
      const uri = encodeURI(
        `${state.env.selfServiceUrl}/v1/self-service/verify?clientId=${
          config.clientID
        }&id=${userID}&responsetype=json&lang=${sessionStore.getLang() || "fi"}`
      );
      const init = { method: "GET", cache: "no-cache", mode: "cors" };
      return fetch(uri, init)
        .then((response) => response)
        .catch((err) => {
          throw err;
        });
    },
    getResetEmailUrl: () => getUrlWithModal("RESET"),
    getRegistrationUrl: () => getUrlWithModal("REGISTER"),
    getVerifyEmailUrl: () => {
      const { id } = userStore.getUser();
      return encodeURI(
        `${state.env.selfServiceUrl}/v1/self-service/verify?clientId=${
          config.clientID
        }&id=${id}&responsetype=json&lang=${sessionStore.getLang() || "fi"}`
      );
    },
  };
};

export default EmailFunctions;
