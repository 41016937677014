import { authenticationEvent, loginFailure, logout, passwordReset, registration, someFailure } from "./eventNames";
export default () => {
  const eventsBuffer = [];
  return {
    getEventsBuffer: () => eventsBuffer,
    sendLoginFailure: (...args) => eventsBuffer.push({ e: loginFailure, args }),
    sendAuthenticationEvent: (...args) => eventsBuffer.push({ e: authenticationEvent, args }),
    sendSomeFailure: (...args) => eventsBuffer.push({ e: someFailure, args }),
    sendLogout: (...args) => eventsBuffer.push({ e: logout, args }),
    sendRegistration: (...args) => eventsBuffer.push({ e: registration, args }),
    sendPasswdReset: (...args) => eventsBuffer.push({ e: passwordReset, args }),
  };
};
