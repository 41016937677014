import styled, { css } from "styled-components";

import { styleErrorFont } from "../../theme/theme.functions";
import { InputFocusStyle } from "../Focus";
import { TextRegular, TextSemiBold } from "../Text";

const errorBorder = css`
  box-shadow: 0 0 0 1px #ff0000;
  border: 1px solid #ff0000;
`;
const focusBorder = css`
  ${InputFocusStyle}
`;
const regularBorder = css`
  box-shadow: 0 0 0 0 #000;
  border: 1px solid rgba(74, 74, 74, 0.5);
`;

export const FieldContainer = styled.div`
  ${TextRegular}

  position: relative;
  background: transparent;
  height: 50px;
  width: 100%;
  border-radius: 2px;
  margin-bottom: 0.289em;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InputContainer = styled.div`
  ${TextRegular}
  display: block;
  width: 100%;
`;

export const InputError = styled.div`
  margin: 0;
`;

export const InputField = styled.input`
  ${TextRegular}
  flex-grow: 1;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-size: 1em;
  margin: 0;
  padding: 10px 15px;
  border-radius: 2px;
  height: 100%;

  ${({ isError, focused }) => {
    if (focused) return focusBorder;
    return isError ? errorBorder : regularBorder;
  }}
`;

export const InputLabel = styled.label`
  ${TextSemiBold(TextRegular)}
  ${(props) => props.isError && styleErrorFont(props.theme)}
  margin-bottom: 0;
`;
