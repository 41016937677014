const keyNames = {
  sub: "id",
  "https://almamedia.net/alias": "name",
  "https://almamedia.net/user_email": "email",
  "https://almamedia.net/user_role": "role",
  "https://almamedia.net/accountHash": "accountHash",
  "https://almamedia.net/ip_account_id": "IPAccountID",
  email_verified: "emailVerified",
  "https://almamedia.net/fromSignUp": "fromSignUp",
  "https://almamedia.net/almaIDHash": "almaIdHash",
  "https://almamedia.net/cdime": "cdime",
};

const renameKey = (idToken, oldKey, newKey) => {
  if (newKey) {
    Object.defineProperty(idToken, newKey, Object.getOwnPropertyDescriptor(idToken, oldKey));
    delete idToken[oldKey];
  }
};

const renameKeys = (idToken) => Object.keys(idToken).forEach((oldKey) => renameKey(idToken, oldKey, keyNames[oldKey]));

export default (idToken) => {
  renameKeys(idToken);
  return idToken;
};
