export default (state, funcs) => async (idTokenData) => {
  const handleError = (e) => {
    if (e.error === "invalid_token" && /\(exp\)/.test(e.errorDescription)) {
      state.tokenExpired = true;
    } else funcs.logger.error("IdToken check error: " + JSON.stringify(e), "warning");
    return false;
  };
  if (typeof idTokenData === "undefined" || !idTokenData) return false;
  try {
    const verifiedIdToken = await funcs.authenticator.verifyIdToken(idTokenData);
    if (verifiedIdToken.error) return handleError(verifiedIdToken);
    return verifiedIdToken;
  } catch (e) {
    return handleError(e);
  }
};
