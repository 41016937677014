import sessionStore from "./storageAPIs/sessionStore";
import { removeUndefined } from "./utils";

const getSpecialCode = (k) => (/timedout/i.test(k) ? "timeout" : null);

const email2username = (email) =>
  email
    .replace("@", "_at_")
    .replace(/[^a-z0-9_+.-]/gi, "")
    .substr(0, 128);

const resolveSendParameters = (loginUsername) =>
  loginUsername.indexOf("@") >= 0
    ? [loginUsername, email2username(loginUsername)]
    : [`${loginUsername}@tunnus.almamedia.fi`, loginUsername];

const Authenticator = (config, webAuth, login) => {
  const state = {
    responseType: "token id_token",
  };

  const signup = async (loginUsername, password, userMetadata) => {
    const [email, username] = resolveSendParameters(loginUsername);
    const response = await fetch(`https://${config.domain}/dbconnections/signup`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_id: config.clientID,
        email,
        password,
        connection: config.realm,
        username,
        user_metadata: userMetadata,
      }),
    });
    const data = await response.json();
    if (response.status === 200) return data;
    throw data;
  };

  const loginUser = async (username, password) => {
    const loginScope = `${config.scope}`;
    try {
      const result = await login(username, password, loginScope);
      return result;
    } catch (error) {
      let code = typeof error === "string" ? error : error.code || error.error || "generic_error";
      const description = error instanceof Object && error.error_description ? error.error_description : null;
      if (description === "unverified_users_not_allowed") code = description;
      const formattedError = { code: getSpecialCode(description) || code, description };
      if (formattedError.code === "access_denied" || formattedError.code === "invalid_user_password") {
        try {
          await signup(username, password, { origin: config.clientID, implicit: "true" });
          return login(username, password, loginScope);
        } catch (signupError) {
          throw formattedError;
        }
      }
      throw formattedError;
    }
  };

  return {
    state,
    signup,
    loginUser,
    verifyIdToken: ({ idToken, nonce }) =>
      new Promise((resolve, reject) =>
        webAuth.validateToken(idToken, nonce, (error, idToken) => (error ? reject(error) : resolve(idToken)))
      ),

    someLogin: (provider, realm = "Almatunnus") =>
      webAuth.authorize({
        connection: provider,
        domain: config.domain,
        redirectUri: config.redirectUrl,
        audience: config.audience,
        responseType: state.responseType,
        realm: realm,
        scope: `${config.scope} gaevent:${provider}`,
      }),

    registerAndLoginUser: async (registerForm, type) => {
      const userMetadata = removeUndefined({
        origin: config.clientID,
        given_name: registerForm.firstName,
        family_name: registerForm.lastName,
        consentGiven: registerForm.consent,
        consentTimestamp: Date.now().toString(),
        type: type || sessionStore.getType(),
        source: sessionStore.getSource(),
        lang: sessionStore.getLang(),
        personal_info: JSON.stringify(
          removeUndefined({
            postal_code: registerForm.postalCode,
            birth_year: registerForm.birthYear,
            phone_number: registerForm.phoneNumber,
          })
        ),
      });
      const scope = "gaevent:registration";
      const { email, password } = registerForm;
      try {
        await signup(email, password, userMetadata);
      } catch (signupError) {
        throw new Error(`Error signing up:  ${signupError}`);
      }
      try {
        return login(email, password, scope);
      } catch (loginError) {
        throw new Error(`Error signing up:  ${loginError}`);
      }
    },
  };
};

export default Authenticator;
