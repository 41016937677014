const LoginHandler = (analytics, scope) => {
  let handleStoreToken = () => {};
  let handleLogin = () => {};
  let handleSetSession = () => {};
  let source;

  const setSource = (src) => {
    source = src;
  };

  const withSession = (storeTokenFunction, setSessionFunction) => {
    handleStoreToken = storeTokenFunction;
    handleSetSession = setSessionFunction;
  };

  const setLoginFunction = (loginFunction) => {
    handleLogin = loginFunction;
  };

  const sendLoginSuccessEvent = (user) => {
    analytics.sendAuthenticationEvent(user, "login", source);
  };

  const sendLoginErrorEvent = () => {
    analytics.sendLoginFailure("login");
  };

  const handleLoginError = (loginError) => {
    sendLoginErrorEvent();
    return { errors: [loginError] };
  };

  const loginUser = async (email, password) => {
    const loginResult = await handleLogin(email, password, scope);
    if (loginResult.statusCode) return loginResult;
    const { accessToken, idToken, idTokenPayload } = loginResult;
    const user = handleSetSession(idTokenPayload);
    handleStoreToken(idToken, loginResult);
    sendLoginSuccessEvent(user);
    return { accessToken, user, idToken };
  };

  const login = async (userData) => {
    const { email, password } = userData;
    try {
      const loginResult = await loginUser(email, password);
      return loginResult;
    } catch (loginError) {
      return handleLoginError(loginError);
    }
  };

  return { login, withSession, setLoginFunction, setSource };
};

export default LoginHandler;
