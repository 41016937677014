/* eslint-disable no-undef */

// Creates CSS font family definition with fallback fonts
export const generateFontFamily = (variantName) =>
  `'${variantName}', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif`;

// Fonts used by Alma Tunnus component
const variants = [
  {
    name: "GalanoGrotesqueAlt-Regular",
    weight: 400,
    style: "normal",
  },
  {
    name: "GalanoGrotesqueAlt-SemiBold",
    weight: 600,
    style: "normal",
  },
  {
    name: "GalanoGrotesqueAlt-Bold",
    weight: 800,
    style: "normal",
  },
];

// Forked from https://github.com/filamentgroup/woff2-feature-test/blob/master/woff2.js
// Added checks to avoid server-side renderiing errors
const supportsWoff2 = async () => {
  if (!("FontFace" in window)) return false;

  const loadResult = await new FontFace(
    "t",
    'url( "data:font/woff2;base64,d09GMgABAAAAAADwAAoAAAAAAiQAAACoAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAABmAALAogOAE2AiQDBgsGAAQgBSAHIBuDAciO1EZ3I/mL5/+5/rfPnTt9/9Qa8H4cUUZxaRbh36LiKJoVh61XGzw6ufkpoeZBW4KphwFYIJGHB4LAY4hby++gW+6N1EN94I49v86yCpUdYgqeZrOWN34CMQg2tAmthdli0eePIwAKNIIRS4AGZFzdX9lbBUAQlm//f262/61o8PlYO/D1/X4FrWFFgdCQD9DpGJSxmFyjOAGUU4P0qigcNb82GAAA" ) format( "woff2" )',
    {}
  )
    .load()
    .then((f) => f.status === "loading" || f.status === "loaded")
    .catch(() => false);
  return loadResult;
};

// Ensure in browser context & required methods available
const supportsPreloading = () =>
  typeof window.requestIdleCallback === "function" && "FontFace" in window && "fonts" in document;

// Generates URL string for the font file
const generateFontUrl = (variant, format) =>
  `https://cdn.almamedia.fi/fonts/GalanoGrotesqueAlt/2018-04-06/${variant}.${format}`;

// Generate CSS Font Face declartion syntax to be injected into CSS
const generateCSSFontFaceDeclarationSyntax = () =>
  variants
    .map((variant) => {
      const woff2Url = generateFontUrl(variant.name, "woff2");
      const woffUrl = generateFontUrl(variant.name, "woff");
      return `
      @font-face {
        font-family: "${variant.name}";
        font-weight: ${variant.weight};
        font-style: "${variant.style}";
        font-display: "swap";
        src: url('${woff2Url}') format('woff2'),
             url('${woffUrl}') format('woff');
      }
    `;
    })
    .join("\n");

const fontPreload = () => {
  const body = document.getElementsByTagName("body")[0];
  const markFontAsLoaded = (variant) => body.classList.add(`alma-font-loaded--${variant.name.toLowerCase()}`);
  window.requestIdleCallback(() => {
    const format = supportsWoff2() ? "woff2" : "woff";

    variants.forEach((variant) =>
      new FontFace(variant.name, `url("${generateFontUrl(variant.name, format)}")`, {
        weight: variant.weight,
        style: variant.style,
      })
        .load()
        .then((loadedFace) => {
          try {
            document.fonts.add(loadedFace);
            markFontAsLoaded(variant, format);
          } catch (e) {
            console.error(`Error setting Alma Font "${variant.name}.${format}"`, e);
          }
        })
        .catch((e) => console.error(`Error preloading Alma Font "${variant.name}.${format}"`, e))
    );
  });
};

const timeoutFontPreload = () => {
  const body = document.getElementsByTagName("body")[0];
  const markFontAsLoaded = (variant) => body.classList.add(`alma-font-loaded--${variant.name.toLowerCase()}`);
  try {
    // Create CSS style which defines the font-face
    const css = generateCSSFontFaceDeclarationSyntax();
    const head = document.head || document.getElementsByTagName("head")[0];
    const style = document.createElement("style");
    style.type = "text/css";
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
    // Create element that actually uses the font-face
    // Required as some browsers do not download font files unless the font
    // is really in use
    variants.forEach((variant) => {
      const fontDiv = document.createElement("DIV");
      fontDiv.setAttribute("style", `font-family: '${variant.name}'; color: transparent;`);
      head.appendChild(fontDiv);
      markFontAsLoaded(variant, "(woff2|woff)");
    });
  } catch (e) {
    console.error(`Error preloading Alma Fonts with CSS+content hack`, e);
  }
};

export const loadFont = (fontUrl) => {
  const script = document.createElement("link");
  script.rel = "preload";
  script.as = "font";
  script.href = fontUrl;
  document.head.appendChild(script);
};

// Preloader
export function preloadAlmaFonts() {
  if (
    typeof window !== "undefined" &&
    typeof document !== "undefined" &&
    document.documentElement &&
    document.documentElement.classList
  ) {
    try {
      if (supportsPreloading()) fontPreload();
      else timeoutFontPreload();
    } catch (e) {
      console.error("Error with Alma font preloading", e);
    }
  }
}
