import React from "react";

import { useUIContext } from "../../providers/UIContextProvider";
import EmailSendingForm from "./EmailSendingForm";

export default () => {
  const { core } = useUIContext();
  const sendPwdless = (email) => core.sendPwdless(email);
  return <EmailSendingForm sendAction={sendPwdless} nextModal="NOPWDSENT" assetScope="passwordless" />;
};
