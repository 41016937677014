import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import styled, { keyframes } from "styled-components";

import { Backdrop, ContentContainer } from "../components/Containers";
import { useUIContext } from "../providers/UIContextProvider";

const NotificationFrame = (WrappedNotification) => {
  const Sub = (props) => {
    const closeNotification = () => dispatch({ type: "notificationClosing", payload: true });
    const { dispatch, notificationClosing } = useUIContext();

    useEffect(() => {
      if (!notificationClosing) return () => {};
      const setNotificationClosed = () =>
        dispatch({ type: "renderNotification", payload: false }, { type: "notificationClosing", payload: false });
      const closeNotification = setTimeout(setNotificationClosed, 200);
      return () => {
        clearTimeout(closeNotification);
      };
    }, [notificationClosing]);

    // eslint-disable-next-line no-use-before-define
    const NotificationContainer = notificationClosing ? ClosingContainer : OpeningContainer;

    return ReactDOM.createPortal(
      <Backdrop role="alertdialog" aria-modal="true">
        <NotificationContainer
          role="document"
          tabindex="0"
          aria-labelledby="notification_label"
          aria-describedby="notification_desc"
        >
          <ContentContainer>
            <WrappedNotification {...props} closeNotification={closeNotification} />
          </ContentContainer>
        </NotificationContainer>
      </Backdrop>,
      document.body
    );
  };

  return Sub;
};

export default NotificationFrame;

// STYLED COMPONENT
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

const open = keyframes`
  from { opacity: 0; transform: translate(-50%, -50%); }
  to { opacity: 1; transform: translate(-50%, 0); }
`;

const close = keyframes`
  from { opacity: 1; transform: translate(-50%, 0); }
  to { opacity: 0; transform: translate(-50%, -50%); }
`;

const Notification = styled.div`
  box-sizing: content-box;
  -webkit-box-sizing: content-box;

  width: calc(100% - 4 * 2px);
  min-width: 275px;
  max-width: 420px;

  position: absolute;
  left: 50%;
  top: 4px;
  transform: translate(-50%, 0);

  padding-bottom: 30px;

  background-color: white;
  border-radius: 2px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  overflow: hidden;

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  svg {
    margin-top: 25px;
    width: 34px;
    height: 34px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.mobileM}) {
    width: calc(100% - 10 * 2px);
    top: 10px;
    padding-bottom: 40px;
    svg {
      margin-top: 35px;
      width: 38px;
      height: 38px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.mobileL}) {
    top: 15px;
  }
`;

const OpeningContainer = styled(Notification)`
  animation: ${open} 0.2s ease-out;
`;

const ClosingContainer = styled(Notification)`
  opacity: 0;
  animation: ${close} 0.2s ease-out;
`;
