import React from "react";
import styled from "styled-components";

import Bullets from "../../components/Bullets";
import { LinkButton, PrimaryButton } from "../../components/Buttons";
import { Container } from "../../components/Containers";
import { H1 } from "../../components/Headers";
import P from "../../components/P";
import { useModalStageContext } from "../../providers/modal/ModalStageContext";
import { useUIContext } from "../../providers/UIContextProvider";
import BackToMainPageLink from "./BackToMainPageLink";

const ReadMoreContainer = styled.div`
  max-width: 300px;
`;

export default () => {
  const { setModalStage } = useModalStageContext();
  const { assets, t } = useUIContext();

  const bulletListAssets = t("pages.signupInfo.bulletList");
  const bulletListHasContent = Array.isArray(bulletListAssets) && bulletListAssets.length >= 1;

  const handleSignupAction = () => {
    if (typeof assets.signupUrl !== "undefined" && assets.signupUrl !== "") window.open(assets.signupUrl, "_blank");
    else setModalStage("REGISTER");
  };
  const handleReadMoreClick = () => window.open(t("labelReadMoreLink"), "_blank");

  return (
    <Container role="dialog" aria-labelledby="heading" aria-describedby="description">
      <H1 id="heading" dangerouslySetInnerHTML={{ __html: t("pages.signupInfo.heading") }} />
      <Container id="description">
        <P paragraphContent={t("pages.signupInfo.description")} centerAlign={!bulletListHasContent} />
        <Bullets page="signupInfo" />
      </Container>
      <ReadMoreContainer>
        <LinkButton onClick={handleReadMoreClick}>
          <span dangerouslySetInnerHTML={{ __html: t("pages.signupInfo.labelReadMore") }} />
        </LinkButton>
      </ReadMoreContainer>
      <PrimaryButton onKeyPress={handleSignupAction} onClick={handleSignupAction}>
        {t("common.labelRegister")}
      </PrimaryButton>
      <BackToMainPageLink />
    </Container>
  );
};
