import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { PrimaryButton } from "../../components/Buttons";
import { H1 } from "../../components/Headers";
import IconDone from "../../components/icons/Done";
import P from "../../components/P";
import { useModalStageContext } from "../../providers/modal/ModalStageContext";
import { useUIContext } from "../../providers/UIContextProvider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  > svg {
    margin: 10px 0 20px 0;
    width: 38px;
    height: 38px;
  }

  > button {
    margin-top: 20px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.mobileL}) {
    min-height: 430px;
  }
`;

const EmailSendingSuccess = ({ assetScope }) => {
  const { t } = useUIContext();
  const { setModalStage, isUniversal } = useModalStageContext();
  const onCloseClick = () => setModalStage(isUniversal ? "LOGIN" : -1);
  return (
    <Container>
      <IconDone />
      <H1 dangerouslySetInnerHTML={{ __html: t(`pages.${assetScope}.sentHeading`) }} />
      <P dangerouslySetInnerHTML={{ __html: t(`pages.${assetScope}.sentDescription`) }} />
      <PrimaryButton type="button" onClick={onCloseClick}>
        {isUniversal ? t("common.labelBack") : t("common.labelClose")}
      </PrimaryButton>
    </Container>
  );
};

EmailSendingSuccess.propTypes = {
  assetScope: PropTypes.string.isRequired,
};

export default EmailSendingSuccess;
