export const initialState = {
  assets: {},
  assetsLoading: true,
  forceKeys: false,
  shouldRenderNotification: false,
  notificationClosing: false,
  user: null,
  error: null,
};

export default (state, { type, payload }) => {
  switch (type) {
    case "assetsLoaded":
      return { ...state, assets: payload, assetsLoading: false };
    case "renderNotification":
      return {
        ...state,
        shouldRenderNotification: payload,
        notificationClosing: payload === false ? false : state.notificationClosing,
      };
    case "notificationClosing":
      return { ...state, notificationClosing: payload };
    case "forceKeys":
      return { ...state, forceKeys: payload };
    case "setUser":
      return { ...state, user: payload };
    case "setError":
      return { ...state, error: payload };
    default:
      throw new Error();
  }
};
