import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import A from "../../components/A";
import AlertMessage from "../../components/AlertMessage";
import Bullets from "../../components/Bullets";
import { LinkButton, PrimaryButton } from "../../components/Buttons";
import Checkbox from "../../components/Checkbox";
import { Container } from "../../components/Containers";
import { LinkFocusStyle } from "../../components/Focus";
import { H1 } from "../../components/Headers";
import Input from "../../components/input/Input";
import InputPassword from "../../components/input/InputPassword";
import P from "../../components/P";
import { TextRegular, TextSmall } from "../../components/Text";
import { useModalStageContext } from "../../providers/modal/ModalStageContext";
import { useUIContext } from "../../providers/UIContextProvider";
import BackToMainPageLink from "./BackToMainPageLink";
import PasswordChecklist from "./PasswordChecklist";

const ConsentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  > div {
    margin-right: 12px;
  }
`;

const ConsentContent = styled.span`
  ${TextRegular}
  color: black;
  > a {
    border-radius: 1px;
    color: ${(props) => props.theme.linkColor};
    text-decoration: underline;
    ${LinkFocusStyle}
    &:hover {
      color: ${(props) => props.theme.linkColor};
      text-decoration: underline;
    }
  }
`;

const FormHeader = styled(Container)`
  text-align: center;
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > div {
    margin-top: 20px;
  }
  > p,
  > ul {
    padding-left: 0;
    margin: 0;
  }
  > p {
    margin-bottom: -10px;
  }
  margin-bottom: 30px;
`;

const Form = styled.form`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  & > div {
    width: 100%;
  }
`;

const ErrorLinksContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserExistsLink = styled(A)`
  ${TextSmall}
  padding: 5px 10px;
  color: ${(props) => props.theme.linkColor};
  margin-top: 8px;
`;

const ConsentErrorContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const RegisterForm = () => {
  const { setModalStage, modalData } = useModalStageContext();
  const { assets, core, t } = useUIContext();
  const bulletListAssets = t("pages.signup.bulletList");
  const hasBulletListContent = Array.isArray(bulletListAssets) && bulletListAssets.length >= 1;
  let blockEvents = false;
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: modalData.email || "",
    consent: false,
    passwordFocused: false,
    performingRegistration: false,
    otherConsent: [],
    errors: [],
    pwdValidities: [],
  });

  const passwordInput = useRef("");
  const firstNameInput = useRef("");
  const lastNameInput = useRef("");
  const emailInput = useRef(modalData.email || "");
  const consentInput = useRef(false);
  const inputFields = {
    password: passwordInput,
    firstName: firstNameInput,
    lastName: lastNameInput,
    email: emailInput,
    user_exists: emailInput,
    consent: consentInput,
  };
  const getInputField = (field) => inputFields[field] || firstNameInput;

  useEffect(() => firstNameInput.current.focus(), []);

  const setPwdFocused = (passwordFocused) => setState({ ...state, passwordFocused });

  const handleChange = ({ target: { value, id } }) => setState({ ...state, [id]: value });

  const getErrorMessage = (e) => ({ error: e.error || e.code, text: t(`errors.${e.code}`) || e.text });
  const getErrorMessages = (errors) => errors.map(getErrorMessage);
  const findErrorsInState = (fieldNames) => state.errors.find((e) => fieldNames.some((f) => f === e.error));
  const findErrorInState = (fieldName) => !!state.errors.find((e) => fieldName === e.error);

  const handleRegister = async (e) => {
    if (e) e.preventDefault();
    if (state.performingRegistration) return false;
    setState({ ...state, performingRegistration: true });

    return core
      .registerAndLoginUser({ ...state, password: passwordInput.current.value }, { source: core.getSource() })
      .then((result) => {
        if (result?.errors || result?.code) {
          let errors;
          if (result?.errors[0].error_description === "unverified_users_not_allowed") {
            errors = [getErrorMessage({ code: result.errors[0].error_description })];
          } else {
            errors = result.code ? [getErrorMessage(result)] : getErrorMessages(result.errors) || {};
          }
          if (errors[0].error && errors[0].error !== "consent") getInputField(errors[0].error).current.focus();

          return setState({
            ...state,
            performingRegistration: false,
            pwdValidities: core.getPasswordValidity(passwordInput.current.value),
            errors,
          });
        }
        return setModalStage(-1);
      });
  };

  const errorFuncs = core.validationErrorGetters;

  const removeErrorFromList = (code) => state.errors.filter((err) => err.error !== code);

  const addErrorToList = (err) => {
    const { errors } = state;
    if (!errors.find((e) => e.error === err.error)) errors.push(err);
    return errors;
  };

  const handleCheckbox = ({ target: { checked, id } }) =>
    setState({ ...state, [id]: checked, errors: checked ? removeErrorFromList(id) : state.errors });

  const shouldDropFormEvent = (e) => {
    const target = e.relatedTarget || e.explicitOriginalTarget || document.activeElement;
    return blockEvents || (target && target.tagName === "BUTTON");
  };

  const handleInputBlur = async ({ nativeEvent }) => {
    const getErrorFunc = errorFuncs[nativeEvent.target.id];
    if (!getErrorFunc || shouldDropFormEvent(nativeEvent)) return false;
    const error = getErrorFunc(nativeEvent.target.value);
    return setState({
      ...state,
      errors: error ? addErrorToList(getErrorMessage(error)) : removeErrorFromList(nativeEvent.target.id),
    });
  };

  const handlePasswordInput = async ({ target: { value } }) =>
    setState({ ...state, pwdValidities: core.getPasswordValidity(value) });

  const handlePasswordBlur = async ({ nativeEvent }) => {
    if (shouldDropFormEvent(nativeEvent)) return false;
    const error = core.validationErrorGetters.password(passwordInput.current.value);
    const errors = error ? addErrorToList(getErrorMessage(error)) : removeErrorFromList("password");
    return setState({
      ...state,
      passwordFocused: false,
      pwdValidities: core.getPasswordValidity(passwordInput.current.value),
      errors,
    });
  };

  const handleKeyPress = (e) => (e.key === "Enter" && !shouldDropFormEvent(e) ? handleRegister(e) : false);
  const backToLoginWithEmail = () => {
    const email = emailInput.current.value;
    setModalStage("LOGIN", core.getEmailValidity(email) ? { username: email } : {});
  };

  const handleMoreInfoClick = () => setModalStage("INFO");

  const displayError = (fieldNames, id = "") => {
    const isError = findErrorsInState(fieldNames);
    return !isError ? <></> : <P id={id} role="alert" paragraphContent={isError.text} />;
  };

  const userExistsError = findErrorInState("user_exists");
  const hasExtensibilityError = findErrorInState("extensibility_error");
  const hasLoginError = findErrorInState("unverified_users_not_allowed");

  const reducer = (arr, { error }) => ({ ...arr, [error]: true });
  const errors = state.errors.reduce(reducer, {});

  const onResetPasswordClick = () => {
    const email = emailInput.current.value;
    const resetUrl = t("pages.login.resetPasswordLink", true);
    return resetUrl
      ? window.open(core.getPasswordResetUrl(resetUrl, email))
      : setModalStage("RESET", core.getEmailValidity(email) ? { username: email } : {});
  };

  const onBlockEvents = () => {
    blockEvents = true;
  };
  const onAllowEvents = () => {
    blockEvents = false;
  };
  return (
    <Container role="dialog" aria-labelledby="alma-tunnus-title" aria-describedby="alma-tunnus-desc">
      <FormHeader>
        <H1 id="alma-tunnus-title" dangerouslySetInnerHTML={{ __html: t("pages.signup.heading") }} />
        <P
          id="alma-tunnus-desc"
          dangerouslySetInnerHTML={{ __html: t("pages.signup.description") }}
          leftAlign={hasBulletListContent}
        />
        {hasBulletListContent && <Bullets page="signup" />}

        {t("pages.signup.infoModalLinkLabel", true) && (
          <LinkButton onMouseDown={onBlockEvents} onMouseUp={onAllowEvents} onClick={handleMoreInfoClick}>
            {t("pages.signup.infoModalLinkLabel")}
          </LinkButton>
        )}
      </FormHeader>
      <Form autoComplete="off" onKeyPress={handleKeyPress} noValidate>
        <FormFields>
          <Input
            label={t("pages.signup.labelFirstname")}
            id="firstName"
            value={state.firstName}
            type="text"
            tabIndex={0}
            ref={firstNameInput}
            onChange={handleChange}
            isError={errors.firstName}
            onBlur={handleInputBlur}
            aria-invalid={!!errors.firstName}
            aria-required="true"
            aria-describedby="firstNameError"
          />
          {displayError(["firstName"], "firstNameError")}

          <Input
            label={t("pages.signup.labelLastname")}
            id="lastName"
            value={state.lastName}
            type="text"
            tabIndex={0}
            ref={lastNameInput}
            onChange={handleChange}
            isError={errors.lastName}
            onBlur={handleInputBlur}
            aria-invalid={!!errors.lastName}
            aria-required="true"
            aria-describedby="lastNameError"
          />
          {displayError(["lastName"], "lastNameError")}

          <Input
            label={t("common.labelEmail")}
            id="email"
            value={modalData.email || state.email}
            type="email"
            tabIndex={0}
            ref={emailInput}
            onChange={handleChange}
            disabled={!!modalData.email}
            autoComplete="off"
            isError={errors.email || userExistsError}
            onBlur={handleInputBlur}
            aria-invalid={!!errors.email || userExistsError}
            aria-describedby="userExistsError emailError userExistsErrorContinueLinks"
            aria-required="true"
          />
          {displayError(["user_exists"], "userExistsError")}
          {displayError(["email"], "emailError")}
          {userExistsError && (
            <ErrorLinksContainer id="userExistsErrorContinueLinks">
              <UserExistsLink
                onClick={(e) => {
                  e.preventDefault();
                  backToLoginWithEmail();
                }}
                tabIndex={0}
                dangerouslySetInnerHTML={{ __html: t("common.labelLogin") }}
              />

              <UserExistsLink
                onClick={(e) => {
                  e.preventDefault();
                  onResetPasswordClick(assets.resetPasswordLinkUrl, emailInput.current.value);
                }}
                tabIndex={0}
                dangerouslySetInnerHTML={{ __html: t("common.labelResetPassword") }}
              />
            </ErrorLinksContainer>
          )}

          <InputPassword
            label={t("passwordInput.label")}
            id="password"
            name="password"
            spellcheck="false"
            autoComplete="new-password"
            type={state.visiblePassword ? "text" : "password"}
            tabIndex={0}
            ref={passwordInput}
            onFocus={() => setPwdFocused(true)}
            onBlur={handlePasswordBlur}
            onInput={handlePasswordInput}
            isError={errors.password}
            aria-invalid={!!errors.password}
            aria-describedby="checkList"
            aria-required="true"
          />
          <PasswordChecklist
            pwdValidities={state.pwdValidities}
            pwdHasFocus={state.passwordFocused}
            allOK={!errors.password}
            id="checkList"
          />
        </FormFields>
        <ConsentContainer>
          <Checkbox
            type="checkbox"
            id="consent"
            name="consent"
            required
            checked={state.consent}
            ref={consentInput}
            onChange={handleCheckbox}
            isError={errors.consent}
            aria-required="true"
          />
          <label htmlFor="consent">
            <ConsentContent
              id="consentContent"
              aria-label={t("pages.signup.aria_consent")}
              dangerouslySetInnerHTML={{
                __html: `${t("pages.signup.consent")} ${t("pages.signup.termsLink").replace(
                  "#",
                  assets.common.termsLink
                )}${t("pages.signup.paddedAnd")}${t("pages.signup.privacyLink").replace(
                  "#",
                  assets.common.privacyLink
                )}`,
              }}
            />
          </label>
        </ConsentContainer>
        <ConsentErrorContainer>{displayError(["consent"])}</ConsentErrorContainer>

        {hasExtensibilityError && (
          <AlertMessage>
            <P role="alert" dangerouslySetInnerHTML={{ __html: t(`errors.${state.errors[0].error}`) }} />
          </AlertMessage>
        )}
        {!hasLoginError ? (
          <PrimaryButton
            tabIndex={0}
            id="alma-tunnus-button-register"
            onClick={handleRegister}
            performingAction={state.performingRegistration}
          >
            {t("common.labelRegister")}
          </PrimaryButton>
        ) : (
          <P
            role="success"
            centerAlign={true}
            dangerouslySetInnerHTML={{ __html: t(`pages.signup.verifyToContinue`) }}
          />
        )}
      </Form>
      <BackToMainPageLink onMouseDown={onBlockEvents} onMouseUp={onAllowEvents} />
    </Container>
  );
};

export default RegisterForm;
