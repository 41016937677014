/**
 * Mandatory (core) parameters:
 * - domain = "tunnus-dev.almamedia.net" or the other
 * - clientID	= Auth0 application identifier
 * - redirectUrl = callback URL after authentication
 * - loginCallback = (error, success) function called after login
 */
const defaultConfig = {
  audience: "",
  scope: "openid profile email",
  realm: "Almatunnus",
  checkSessionCallback: () => {},
  loginCallback: () => {},
  debugMode: false,
  disableGa: false,
};

export default defaultConfig;
