import React from "react";
import styled from "styled-components";

const Done = () => (
  <Icon clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="1.41421" viewBox="0 0 302 302">
    <g fill="#93b91f">
      <path
        d="m151.205 0c46.689.222 92.29 23.159 120.272 60.564 27.957 37.373 37.29 88.11 23.585 133.622-12.783 42.449-45.374 78.621-86.548 95.752-45.041 18.74-99.079 13.866-140.15-13.002-41.6-27.213-68.364-75.537-68.364-126.219 0-49.241 25.622-97.712 67.228-125.468 24.427-16.296 53.548-25.109 83.002-25.249h.975zm-.886 28c-44.447.212-87.536 26.115-108.39 65.909-17.57 33.529-18.465 75.207-1.997 109.631 16.49 34.468 49.813 60.459 87.545 67.698 38.305 7.348 79.732-4.704 108.15-31.961 23.86-22.885 37.914-55.558 37.808-88.958-.164-51.631-35.567-101.55-87.322-117.135-11.318-3.408-23.141-5.127-34.997-5.184-.265 0-.531 0-.797 0z"
        fillRule="nonzero"
      />
      <path d="m139.282 168.286 59.423-76.411c1.018-1.31 2.909-1.546 4.219-.527l18.989 14.767c1.311 1.019 1.547 2.91.528 4.22l-76.036 97.773c-.33.425-.752.737-1.216.929l-.017.007s-.012.005-.012.005c-.961.388-2.098.268-2.974-.413 0 0-60.266-46.868-60.266-46.868-1.31-1.018-1.546-2.909-.528-4.219l14.768-18.989c1.019-1.31 2.909-1.547 4.219-.528z" />
    </g>
  </Icon>
);

export default Done;

const Icon = styled.svg``;
