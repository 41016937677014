import PropTypes from "prop-types";
import React from "react";
import { ThemeProvider } from "styled-components";

import themeSettings from "../theme/theme.settings";
import { useUIContext } from "./UIContextProvider";

const StyleProvider = ({ children }) => {
  const { styles } = useUIContext().assets;
  return <ThemeProvider theme={{ ...themeSettings, ...styles }}>{children}</ThemeProvider>;
};

StyleProvider.defaultProps = {
  loadBackground: false,
};

StyleProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default StyleProvider;
