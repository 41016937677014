import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import FocusTrap from "focus-trap-react";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import styled from "styled-components";

import useCmpObserver from "./hooks/useCmpObserver";
import ModalFrame from "./modals/ModalFrame";
import NotificationBase from "./notifications/NotificationBase";
import { useModalStageContext } from "./providers/modal/ModalStageContext";
import { useUIContext } from "./providers/UIContextProvider";
import decodeURLParams from "./utils/helper-functions";
import sessionStore from "../core/storageAPIs/sessionStore";

const ViewRoot = ({ actions }) => {
  const { core, dispatch, shouldRenderNotification } = useUIContext();
  const { modalStage, setModalStage } = useModalStageContext();
  const cmpLoaded = typeof window !== "undefined" ? useCmpObserver(document.body) : false;

  const openAlmaLogin = async (options) => {
    const result = await core.checkExternalSession();
    if (!result) {
      options?.source && sessionStore.setSource(options?.source);
      options?.type && sessionStore.setType(options?.type);
      options?.loginState && sessionStore.setLoginState(options?.loginState);
      if (!(core.browserIsSupported() && core.canPopup()) || core.isRedirectToHostedPage()) {
        core.redirectToHostedLogin(options);
      } else {
        const email = options?.email;
        const stage = typeof options !== "string" ? options?.stage || options?.modal : options;
        core.setSource(options?.source, options?.type, options?.loginState);
        //! TODO remove modal for 3.0
        setModalStage(stage || "LOGIN", { email });
      }
    }
  };

  const openAlmaRegister = (options) => core.redirectToHostedRegistration(options);

  const showLoginNotification = () => dispatch({ type: "renderNotification", payload: true });

  useEffect(() => {
    if (actions !== undefined) {
      actions({
        openAlmaLogin,
        redirectRegister: openAlmaRegister,
        modalOpen: () => {
          return modalStage !== -1;
        },
        showLoginNotification,
        getIdJWT: core.getIdJWT,
        logout: core.logout,
        checkSession: core.checkSession,
        checkExternalSession: core.checkExternalSession,
        getAccessToken: core.getAccessToken,
        getIdToken: core.getIdToken,
        getRegistrationUrl: core.getRegistrationUrl,
        getVerifyEmailUrl: core.getVerifyEmailUrl,
        idTokenIsValid: core.idTokenIsValid,
        getUser: core.getUser,
        getUpdatedUser: core.getUpdatedUser,
        reset: core.reset,
        clearUrl: core.clearUrl,
        canPopup: core.canPopup,
        cookiesEnabled: core.cookiesEnabled,
        registerAndLoginUser: core.registerAndLoginUser,
        getLogoutHandler: core.getLogoutHandler,
        getLoginHandler: core.getLoginHandler,
      });
    }

    if (typeof window !== "undefined") {
      // If query string has param: M then change modalStage
      const urlParams = decodeURLParams(window.location.search);
      if (urlParams?.m) {
        setModalStage(urlParams.m);
      }
    }
  }, []);

  useEffect(() => {
    if (typeof document !== "undefined" && modalStage !== -1) {
      const targetElement = document.querySelector(".AlmaTunnusComponent");
      disableBodyScroll(targetElement);
      return () => {
        enableBodyScroll(targetElement);
        clearAllBodyScrollLocks();
      };
    }
    return () => {};
  });

  if (modalStage === -1) {
    return <NotificationBase />;
  }
  return (
    <>
      <FocusTrap active={!(cmpLoaded || shouldRenderNotification)}>
        <RootContainer data-alma-tunnus-component-version={core.env.componentVersion}>
          <ModalFrame />
        </RootContainer>
      </FocusTrap>
      <NotificationBase />
    </>
  );
};

ViewRoot.defaultProps = {
  actions: () => {},
};

ViewRoot.propTypes = {
  actions: PropTypes.func,
};

export default ViewRoot;

const RootContainer = styled.div`
  font-size: ${(props) => props.theme.fontNormal};
`;
