import React from "react";
import styled from "styled-components";

const Google = () => (
  <Icon clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.41421" viewBox="0 0 33 33">
    <g fillRule="nonzero">
      <path
        d="m30.65 0h-28.947c-.941 0-1.703.76-1.703 1.697v28.856c0 .937.762 1.697 1.703 1.697h28.947c.941 0 1.703-.76 1.703-1.697v-28.856c0-.937-.762-1.697-1.703-1.697z"
        fill="#fff"
      />
      <path
        d="m24.293 16.284c0-.696-.057-1.203-.179-1.729h-7.967v3.138h4.676c-.094.78-.603 1.955-1.734 2.744l-.016.106 2.519 1.945.174.017c1.603-1.476 2.527-3.646 2.527-6.221z"
        fill="#4285f4"
      />
      <path
        d="m16.147 24.554c2.291 0 4.214-.752 5.619-2.049l-2.677-2.068c-.717.498-1.678.846-2.942.846-2.243 0-4.148-1.475-4.827-3.514l-.099.008-2.619 2.02-.034.095c1.395 2.763 4.261 4.662 7.579 4.662z"
        fill="#34a853"
      />
      <path
        d="m11.32 17.769c-.179-.527-.282-1.091-.282-1.673 0-.583.103-1.147.273-1.673l-.005-.112-2.652-2.053-.086.041c-.575 1.147-.905 2.434-.905 3.797 0 1.362.33 2.65.905 3.796z"
        fill="#fbbc05"
      />
      <path
        d="m16.147 10.908c1.594 0 2.668.686 3.281 1.26l2.395-2.331c-1.471-1.363-3.385-2.199-5.676-2.199-3.318 0-6.184 1.898-7.579 4.661l2.743 2.124c.688-2.039 2.593-3.515 4.836-3.515z"
        fill="#eb4335"
      />
    </g>
  </Icon>
);

export default Google;

const Icon = styled.svg``;
